import React, { useState } from 'react';
import { Box, Button, CircularProgress, Grid2, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

interface ImageUploadProps {
  onUploadSuccess: (imageUrl: string) => void;
  index: number;
}

const ImageUploadTrip: React.FC<ImageUploadProps> = ({ onUploadSuccess, index }) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [uploading, setUploading] = useState(false);
  const [previewUrls, setPreviewUrls] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError(null);
    const files = event.target.files;
    if (files) {
      const fileArray = Array.from(files);
      let goodFiles = [];
      let previews = [];
      for (const file of fileArray) {
        if (!file.type.match(/image\/(jpg|jpeg|png|gif|webp|heic|heif)/)) {
          setError('Only image files (jpg|jpeg|png|gif|webp|heic|heif) are allowed.');
          return;
        }
        if (file.size > 5 * 1024 * 1024) {
          setError('File size must be less than 5MB.');
          return;
        }

        goodFiles.push(file);

        // Create a preview URL
        const reader = new FileReader();
        reader.onloadend = () => {
          previews.push(reader.result as string);
          if (previews.length === fileArray.length) {
            setPreviewUrls(previews);
          }
        };
        reader.readAsDataURL(file);
      }

      setSelectedFiles(goodFiles);
    }
  };

  const handleUpload = async () => {
    if (!selectedFiles) {
      setError('No file selected');
      return;
    }

    setUploading(true);
    setError(null);

    try {
      for (const file of selectedFiles) {
        const formData = new FormData();
        formData.append('file', file);

        const accessToken = localStorage.getItem('accessToken');

        const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/upload/image`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Failed to upload image');
        }

        const data = await response.json();
        const imageUrl = data.imageUrl;

        onUploadSuccess(imageUrl);
      }
      // Reset state
      setSelectedFiles([]);
      setPreviewUrls([]);
    } catch (err) {
      console.error(err);
      setError('Failed to upload image');
    } finally {
      setUploading(false);
    }
  };

  const deleteImage = (index: number) => {
    const updatedImages = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(updatedImages);
    const updatedPreviews = previewUrls.filter((_, i) => i !== index);
    setPreviewUrls(updatedPreviews);
  };

  return (
    <Box>
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id={'image-upload-input' + index}
        type="file"
        multiple
        onChange={handleFileChange}
      />
      <Box className="d-flex justify-content-between">
        <label htmlFor={'image-upload-input' + index}>
          <Button variant="outlined" component="span">
            Choose Images
          </Button>
        </label>

        {selectedFiles.length > 0 && (
          <Button variant="contained" onClick={handleUpload} disabled={uploading}>
            {uploading ? 'Uploading...' : 'Upload Images'}
          </Button>
        )}
      </Box>

      <Grid2 container spacing={2} sx={{ mt: 1 }}>
        {selectedFiles &&
          selectedFiles.map((file, index) => (
            <Grid2 key={index}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="body2">{file.name}</Typography>
                <IconButton aria-label="delete" onClick={() => deleteImage(index)}>
                  <DeleteIcon />
                </IconButton>
              </Box>

              {previewUrls[index] && (
                <Box component="img" src={previewUrls[index]} alt="Preview" sx={{ height: 100, mt: 1 }} />
              )}
            </Grid2>
          ))}
      </Grid2>
      {uploading && (
        <Box sx={{ mt: 2 }}>
          <CircularProgress size={24} />
        </Box>
      )}
      {error && (
        <Typography variant="body2" color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default ImageUploadTrip;
