import React from 'react';
import { Box, Button, Typography, List, ListItem, ListItemText, Alert, CircularProgress } from '@mui/material';
import { Membership } from '../../../generated/graphql';

interface ReviewDetailsProps {
  formData: Partial<Membership>;
  onBack: () => void;
  onNext: () => void;
}

const ReviewDetails: React.FC<ReviewDetailsProps> = ({ formData, onBack, onNext }) => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  const handleDownloadPdf = async () => {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
      alert('Please log in to download the PDF.');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/pdf-download/generate`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to generate PDF');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'membership-request.pdf');
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    } catch (err: any) {
      console.error(err);
      setError(err.message || 'Failed to download PDF');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Review Your Details
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="First Name" secondary={formData.firstname || '-'} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Last Name" secondary={formData.lastname || '-'} />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Birth Date"
            secondary={formData.birthDate ? new Date(formData.birthDate).toLocaleDateString() : '-'}
          />
        </ListItem>
        <ListItem>
          <ListItemText primary="Permanent Address" secondary={formData.permanentAddress || '-'} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Contact Address" secondary={formData.contactAddress || '-'} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Phone Number" secondary={formData.phone || '-'} />
        </ListItem>
      </List>

      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}

      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Button variant="outlined" onClick={onBack}>
          Back
        </Button>
        <Box>
          {/* <Button variant="outlined" color="primary" onClick={handleDownloadPdf} sx={{ mr: 2 }} disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Download Pre-filled PDF'}
          </Button> */}
          <Button
            variant="outlined"
            color="primary"
            onClick={() =>
              window.open(
                'https://drive.google.com/file/d/1eLAdVL3CB9TCQL3Fmg9BEgPK9z4XhiTD/view?usp=sharing',
                '_blank'
              )
            }
            sx={{ mr: 2 }}
          >
            Download PDF (Preview)
          </Button>
          <Button variant="contained" color="primary" onClick={onNext}>
            Next
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ReviewDetails;
