import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  FormControlLabel,
  Checkbox,
  Button,
} from '@mui/material';
import { Role } from '../../../../generated/graphql';

interface ManageRolesDialogProps {
  isOpen: boolean;
  onClose: () => void;
  newRoles: Role[];
  handleToggleRole: (role: Role) => void;
}

const ManageRolesDialog: React.FC<ManageRolesDialogProps> = ({ isOpen, onClose, newRoles, handleToggleRole }) => (
  <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
    <DialogTitle>Manage User Roles</DialogTitle>
    <DialogContent>
      <FormControl component="fieldset">
        {Object.values(Role).map((role) => (
          <FormControlLabel
            key={role}
            control={<Checkbox checked={newRoles.includes(role)} onChange={() => handleToggleRole(role)} name={role} />}
            label={role}
          />
        ))}
      </FormControl>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

export default ManageRolesDialog;
