import React from 'react';
import { Formik, Form, Field, FieldProps } from 'formik';
import { TextField, Button, Box, Grid, Typography, Link } from '@mui/material';
import * as Yup from 'yup';
import { Membership } from '../../../generated/graphql';
import { useTranslation } from 'react-i18next';

interface MembershipFormProps {
  initialValues: Partial<Membership>;
  onNext: (values: Partial<Membership>) => void;
  loading: boolean;
}

const validationSchema = Yup.object({
  firstname: Yup.string().required('First name is required'),
  lastname: Yup.string().required('Last name is required'),
  birthDate: Yup.date().required('Birth date is required').max(new Date(), 'Birth date cannot be in the future'),
  permanentAddress: Yup.string().required('Permanent address is required'),
  contactAddress: Yup.string().required('Contact address is required'),
  phone: Yup.string()
    .required('Phone number is required')
    .matches(/^[+]?[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/, 'Phone number is not valid'),
});

const MembershipForm: React.FC<MembershipFormProps> = ({ initialValues, onNext, loading }) => {
  const { t } = useTranslation();

  const formInitialValues = {
    firstname: initialValues.firstname || '',
    lastname: initialValues.lastname || '',
    birthDate: initialValues.birthDate || '',
    permanentAddress: initialValues.permanentAddress || '',
    contactAddress: initialValues.contactAddress || '',
    phone: initialValues.phone || '',
  };

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        onNext(values);
      }}
    >
      {({ isSubmitting, touched, errors }) => (
        <Form>
          <Box sx={{ mb: 4 }}>
            <Typography variant="h4" gutterBottom>
              {t('membership_form_title', 'Membership Application')}
            </Typography>
            <Typography variant="body1" color="textSecondary">
              {t('membership_info_text')}{' '}
              <Link
                href="https://drive.google.com/file/d/1mjapc7O-gq48UP10jPEvLFZGOMszYg1A/view?usp=sharing"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('membership_info_link')}
              </Link>
              .
            </Typography>
          </Box>
          <Grid container spacing={2}>
            {/* First Name */}
            <Grid item xs={12} sm={6}>
              <Field name="firstname">
                {({ field }: FieldProps) => (
                  <TextField
                    {...field}
                    label="First Name"
                    fullWidth
                    error={touched.firstname && Boolean(errors.firstname)}
                    helperText={touched.firstname && errors.firstname}
                    variant="outlined"
                    margin="normal"
                  />
                )}
              </Field>
            </Grid>

            {/* Last Name */}
            <Grid item xs={12} sm={6}>
              <Field name="lastname">
                {({ field }: FieldProps) => (
                  <TextField
                    {...field}
                    label="Last Name"
                    fullWidth
                    error={touched.lastname && Boolean(errors.lastname)}
                    helperText={touched.lastname && errors.lastname}
                    variant="outlined"
                    margin="normal"
                  />
                )}
              </Field>
            </Grid>

            {/* Birth Date */}
            <Grid item xs={12} sm={6}>
              <Field name="birthDate">
                {({ field }: FieldProps) => (
                  <TextField
                    {...field}
                    label="Birth Date"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    error={touched.birthDate && Boolean(errors.birthDate)}
                    helperText={touched.birthDate && errors.birthDate}
                    variant="outlined"
                    margin="normal"
                  />
                )}
              </Field>
            </Grid>

            {/* Phone Number */}
            <Grid item xs={12} sm={6}>
              <Field name="phone">
                {({ field }: FieldProps) => (
                  <TextField
                    {...field}
                    label="Phone Number"
                    fullWidth
                    error={touched.phone && Boolean(errors.phone)}
                    helperText={touched.phone && errors.phone}
                    variant="outlined"
                    margin="normal"
                  />
                )}
              </Field>
            </Grid>

            {/* Permanent Address */}
            <Grid item xs={12}>
              <Field name="permanentAddress">
                {({ field }: FieldProps) => (
                  <TextField
                    {...field}
                    label="Permanent Address"
                    fullWidth
                    multiline
                    rows={2}
                    error={touched.permanentAddress && Boolean(errors.permanentAddress)}
                    helperText={touched.permanentAddress && errors.permanentAddress}
                    variant="outlined"
                    margin="normal"
                  />
                )}
              </Field>
            </Grid>

            {/* Contact Address */}
            <Grid item xs={12}>
              <Field name="contactAddress">
                {({ field }: FieldProps) => (
                  <TextField
                    {...field}
                    label="Contact Address"
                    fullWidth
                    multiline
                    rows={2}
                    error={touched.contactAddress && Boolean(errors.contactAddress)}
                    helperText={touched.contactAddress && errors.contactAddress}
                    variant="outlined"
                    margin="normal"
                  />
                )}
              </Field>
            </Grid>
          </Grid>

          {/* Next Button */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
            <Button type="submit" variant="contained" color="primary" disabled={isSubmitting || loading}>
              {loading ? 'Submitting...' : 'Next'}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default MembershipForm;
