import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TablePagination,
  CircularProgress,
  Alert,
  TextField,
  Snackbar,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useQuery, useMutation } from '@apollo/client';

import { useDebounce } from 'use-debounce';
import {
  User,
  MembershipStatusEnum,
  UpdateMembershipStatusMutation,
  UpdateMembershipStatusMutationVariables,
  UsersQuery,
  UsersQueryVariables,
} from '../../../../generated/graphql';
import { USERS_QUERY } from '../../../../graphql/users';
import MembershipReviewModal from '../../../Memberships/MembershipReviewModal';
import { UPDATE_MEMBERSHIP_STATUS_MUTATION } from '../../../../graphql/membership';

interface PendingMembershipsTableProps {
  refetchParent: () => void;
}

const PendingMembershipsTable: React.FC<PendingMembershipsTableProps> = ({ refetchParent }) => {
  // State variables
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchInput, setSearchInput] = useState('');
  const [debouncedSearchInput] = useDebounce(searchInput, 300);

  // State for query variables
  const [queryVariables, setQueryVariables] = useState<UsersQueryVariables>({
    first: rowsPerPage,
    after: null,
    filter: {
      query: debouncedSearchInput,
      membershipStatus: MembershipStatusEnum.Pending,
    },
  });

  // Ref for the search input
  const searchInputRef = useRef<HTMLInputElement>(null);

  // State for selected user
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  // State for Membership Review Modal
  const [isMembershipDialogOpen, setIsMembershipDialogOpen] = useState(false);

  // Snackbar state for feedback messages
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Mutation for updating membership status
  const [updateMembershipStatus] = useMutation<UpdateMembershipStatusMutation, UpdateMembershipStatusMutationVariables>(
    UPDATE_MEMBERSHIP_STATUS_MUTATION
  );

  // Fetch users with pending memberships
  const { data, loading, error, refetch } = useQuery<UsersQuery, UsersQueryVariables>(USERS_QUERY, {
    variables: queryVariables,
    fetchPolicy: 'cache-and-network',
  });

  // Effect to reset pagination when search input or rows per page change
  useEffect(() => {
    setPage(0);
    const variables: UsersQueryVariables = {
      first: rowsPerPage,
      after: null,
      filter: {
        query: debouncedSearchInput,
        membershipStatus: MembershipStatusEnum.Pending,
      },
      last: undefined,
      before: undefined,
    };
    setQueryVariables(variables);
    refetch(variables);
  }, [debouncedSearchInput, rowsPerPage, refetch]);

  // Search input change handler
  const handleSearchInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
  }, []);

  // Handle change page
  const handleChangePage = useCallback(
    (event: unknown, newPage: number) => {
      if (!data?.users?.pageInfo) return;

      const { hasNextPage, endCursor, hasPreviousPage, startCursor } = data.users.pageInfo;

      if (newPage > page && hasNextPage) {
        // Forward pagination: Set 'first' and 'after', ensure 'last' and 'before' are undefined
        const variables: UsersQueryVariables = {
          first: rowsPerPage,
          after: endCursor,
          filter: {
            query: debouncedSearchInput,
            membershipStatus: MembershipStatusEnum.Pending,
          },
          last: undefined,
          before: undefined,
        };
        setQueryVariables(variables);
        refetch(variables);
      } else if (newPage < page && hasPreviousPage) {
        // Backward pagination: Set 'last' and 'before', ensure 'first' and 'after' are undefined
        const variables: UsersQueryVariables = {
          last: rowsPerPage,
          before: startCursor,
          filter: {
            query: debouncedSearchInput,
            membershipStatus: MembershipStatusEnum.Pending,
          },
          first: undefined,
          after: undefined,
        };
        setQueryVariables(variables);
        refetch(variables);
      }
      setPage(newPage);
    },
    [page, data, rowsPerPage, debouncedSearchInput, refetch]
  );

  // Handle change rows per page
  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newRowsPerPage = parseInt(event.target.value, 10);
      setRowsPerPage(newRowsPerPage);
      setPage(0);
      const variables: UsersQueryVariables = {
        first: newRowsPerPage,
        after: null,
        filter: {
          query: debouncedSearchInput,
          membershipStatus: MembershipStatusEnum.Pending,
        },
        last: undefined,
        before: undefined,
      };
      setQueryVariables(variables);
      refetch(variables);
    },
    [debouncedSearchInput, refetch]
  );

  // Handle opening the Membership Review Modal
  const handleOpenMembershipDialog = useCallback((user: User) => {
    setSelectedUser(user);
    setIsMembershipDialogOpen(true);
  }, []);

  // Handle closing the Membership Review Modal
  const handleCloseMembershipDialog = useCallback(() => {
    setIsMembershipDialogOpen(false);
    setSelectedUser(null);
  }, []);

  // Handle Accept Membership
  const handleAcceptMembership = useCallback(async () => {
    if (selectedUser) {
      try {
        await updateMembershipStatus({
          variables: { userId: selectedUser.id, status: MembershipStatusEnum.Active },
        });
        refetch(queryVariables);
        refetchParent(); // Refetch parent data if necessary
        handleCloseMembershipDialog();
        setSnackbarMessage('Membership accepted.');
        setSnackbarOpen(true);
      } catch (error) {
        console.error(error);
        setSnackbarMessage('Error accepting membership.');
        setSnackbarOpen(true);
      }
    }
  }, [selectedUser, updateMembershipStatus, refetch, queryVariables, refetchParent, handleCloseMembershipDialog]);

  // Handle Reject Membership
  const handleRejectMembership = useCallback(async () => {
    if (selectedUser) {
      try {
        await updateMembershipStatus({
          variables: { userId: selectedUser.id, status: MembershipStatusEnum.Rejected },
        });
        refetch(queryVariables);
        refetchParent(); // Refetch parent data if necessary
        handleCloseMembershipDialog();
        setSnackbarMessage('Membership rejected.');
        setSnackbarOpen(true);
      } catch (error) {
        console.error(error);
        setSnackbarMessage('Error rejecting membership.');
        setSnackbarOpen(true);
      }
    }
  }, [selectedUser, updateMembershipStatus, refetch, queryVariables, refetchParent, handleCloseMembershipDialog]);

  // Ensure focus remains on search input
  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [debouncedSearchInput]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    console.error(error.message);
    return <Alert severity="error">Error loading pending memberships.</Alert>;
  }

  return (
    <>
      {/* Search Panel */}
      <Box mb={3}>
        <TextField
          inputRef={searchInputRef}
          label="Search Pending Memberships"
          variant="outlined"
          value={searchInput}
          onChange={handleSearchInputChange}
          fullWidth
        />
      </Box>

      {/* Pending Memberships Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>User Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Requested At</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.users?.edges?.map(({ node }) => (
              <TableRow key={node.id}>
                <TableCell>
                  {node.firstname && node.lastname ? (
                    <Button component={RouterLink} to={`/user/${node.id}`} variant="text" color="primary">
                      {`${node.firstname} ${node.lastname}`}
                    </Button>
                  ) : (
                    'No Name'
                  )}
                </TableCell>
                <TableCell>{node.email || 'N/A'}</TableCell>
                <TableCell>{node.phone || 'N/A'}</TableCell>
                <TableCell>{node.membership?.status || 'N/A'}</TableCell>
                <TableCell>
                  {node.membership?.createdAt ? new Date(node.membership.createdAt).toLocaleDateString() : 'N/A'}
                </TableCell>
                <TableCell align="right">
                  {/* Review Button */}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenMembershipDialog(node as User)}
                    startIcon={<CheckCircleIcon />}
                  >
                    Review
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            {/* Handle Empty State */}
            {data?.users?.edges?.length === 0 && (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No pending membership requests.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        {/* Pagination */}
        <TablePagination
          component="div"
          count={data?.users?.totalCount || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Rows per page"
        />
      </TableContainer>

      {/* Membership Review Modal */}
      {selectedUser && (
        <MembershipReviewModal
          open={isMembershipDialogOpen}
          user={selectedUser}
          onClose={handleCloseMembershipDialog}
          onAccept={handleAcceptMembership}
          onReject={handleRejectMembership}
        />
      )}

      {/* Snackbar for feedback messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </>
  );
};

export default PendingMembershipsTable;
