import React, { useState } from 'react';
import { Container, Typography, Alert } from '@mui/material';
import { isAdminGuard } from '../../utils/isAdminGuard';
import { useAuth } from '../../contexts/AuthContext';
import UserTabs from '../../components/Admin/UserManagement/UserTabs/UserTabs';
import CustomTabPanel from '../../components/Admin/UserManagement/CustomTabPanel/CustomTabPanel';
import AllUsersTab from '../../components/Admin/UserManagement/AllUsersTab/AllUsersTab';
import PendingMembershipsTable from '../../components/Admin/UserManagement/PendingMemberships/PendingMembershipsTable';

const UserManagementPage: React.FC = () => {
  const { user } = useAuth();

  // State for Tabs
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  // Guard to prevent non-admins from accessing the page
  if (!isAdminGuard(user)) {
    return (
      <Container maxWidth="sm" sx={{ mt: 4 }}>
        <Alert severity="error">You do not have permission to access this page.</Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        User Management
      </Typography>

      {/* Tabs */}
      <UserTabs value={tabValue} onChange={handleTabChange} />

      <CustomTabPanel value={tabValue} index={0}>
        <AllUsersTab />
      </CustomTabPanel>

      <CustomTabPanel value={tabValue} index={1}>
        <PendingMembershipsTable refetchParent={() => {}} />
      </CustomTabPanel>
    </Container>
  );
};

export default UserManagementPage;
