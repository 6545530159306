import React from 'react';
import { useParams } from 'react-router-dom';
import {
  Container,
  Box,
  Grid,
  Typography,
  Divider,
  CircularProgress,
  Alert,
  Avatar,
  Card,
  CardContent,
  Chip,
} from '@mui/material';
import { useQuery } from '@apollo/client';
import { MembershipQuery, UserQuery } from '../../generated/graphql';
import { USER_QUERY } from '../../graphql/users';
import { MEMBERSHIP_QUERY } from '../../graphql/membership';
import StarIcon from '@mui/icons-material/Star';

const UserPage: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();

  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useQuery<UserQuery>(USER_QUERY, {
    variables: { id: userId },
  });

  const {
    data: memberData,
    loading: memberLoading,
    error: memberError,
  } = useQuery<MembershipQuery>(MEMBERSHIP_QUERY, {
    variables: { userId },
  });

  if (userLoading || memberLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (userError) {
    return (
      <Container maxWidth="sm">
        <Alert severity="error">
          Error fetching user data: {userError.message}
        </Alert>
      </Container>
    );
  }

  if (memberError) {
    console.error(memberError.message);
  }

  const user = userData?.user;
  const membership = memberData?.membership;

  if (!user) {
    return (
      <Container maxWidth="sm">
        <Alert severity="info">No user data available.</Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      {/* User Header */}
      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <Avatar
          src={user?.imageUrl || '/images/default_avatar.png'}
          alt={`${user.firstname} ${user.lastname}`}
          sx={{ width: 100, height: 100, mx: 'auto', mb: 2 }}
        />
        <Typography variant="h5" gutterBottom>
          {user.firstname} {user.lastname}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {user.email}
        </Typography>
        {/* Display Roles */}
        <Box mt={2}>
          {user.roles?.map((role) => (
            <Chip
              key={role}
              label={role}
              icon={<StarIcon />}
              color="primary"
              variant="outlined"
              sx={{ mr: 1 }}
            />
          ))}
        </Box>
      </Box>

      <Grid container spacing={3}>
        {/* Profile Details */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Profile Details
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" color="textSecondary">
                    First Name
                  </Typography>
                  <Typography variant="body1">
                    {user.firstname || 'N/A'}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Last Name
                  </Typography>
                  <Typography variant="body1">
                    {user.lastname || 'N/A'}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Phone
                  </Typography>
                  <Typography variant="body1">{user.phone || 'N/A'}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Birth Date
                  </Typography>
                  <Typography variant="body1">
                    {user.birthDate
                      ? new Date(user.birthDate).toLocaleDateString()
                      : 'N/A'}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Permanent Address
                  </Typography>
                  <Typography variant="body1">
                    {user.permanentAddress || 'N/A'}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Contact Address
                  </Typography>
                  <Typography variant="body1">
                    {user.contactAddress || 'N/A'}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Membership Details */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Membership Details
              </Typography>
              <Divider sx={{ mb: 2 }} />
              {membership ? (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" color="textSecondary">
                      Status
                    </Typography>
                    <Typography variant="body1">{membership.status}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" color="textSecondary">
                      Created At
                    </Typography>
                    <Typography variant="body1">
                      {new Date(membership.createdAt).toLocaleDateString()}
                    </Typography>
                  </Grid>
                  {membership.approvedBy && (
                    <Grid item xs={12}>
                      <Typography variant="subtitle2" color="textSecondary">
                        Approved By
                      </Typography>
                      <Typography variant="body1">
                        {membership.approvedBy.firstname}{' '}
                        {membership.approvedBy.lastname}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              ) : (
                <Typography variant="body1">No active membership.</Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default UserPage;
