// src/components/TripDetail/TripDetail.tsx

import React from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import { Trip } from '../../generated/graphql';
import TripSummary from './TripSummary';
import BookNowSection from './BookNowSection';
import DOMPurify from 'dompurify';
import ImageGallery from './ImageGallery/ImageGallery';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import StarIcon from '@mui/icons-material/Star';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Box, Typography, Button, Grid } from '@mui/material';
import { Container, Row, Col } from 'react-bootstrap';
import { t } from 'i18next';
import { DateTime } from 'luxon';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';

interface TripDetailProps {
  trip: Trip;
}

const TripDetail: React.FC<TripDetailProps> = ({ trip }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Sanitize the HTML content to prevent XSS attacks
  const sanitizedDescription = DOMPurify.sanitize(trip?.description ?? '');
  return (
    <Container fluid style={{ padding: '16px', backgroundColor: '#F7F5F2' }}>
      {/* Header */}
      <Row className="mb-3">
        <Col>
          <Box display="flex" alignItems="center">
            <Button
              sx={{
                '&:hover': {
                  backgroundColor: '#aeaeae47',
                },
                color: 'black',
              }}
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon fontSize={isMobile ? 'small' : 'medium'} />
              <Typography variant={isMobile ? 'subtitle1' : 'h6'}>
                {t('back')}
              </Typography>
            </Button>
          </Box>
        </Col>
      </Row>

      {/* Main Image Section */}
      <ImageGallery images={trip.images!} />

      {/* Trip Summary */}
      <TripSummary trip={trip} />

      {/* Description Section */}
      <Row style={{ marginBottom: '16px' }}>
        <Col>
          <Typography variant={isMobile ? 'h5' : 'h3'} fontWeight="bold">
            {trip.title}
          </Typography>
          <Box display="flex" alignItems="center" sx={{ marginBottom: '8px' }}>
            <StarIcon fontSize="small" sx={{ color: '#FFD700' }} />
            <Typography variant="body2">4.7 (9k reviews)</Typography>
          </Box>
          <Box display="flex" sx={{ marginBottom: '16px' }}>
            <Box display="flex" alignItems="center">
              <CalendarMonthIcon fontSize="small" sx={{ color: 'primary' }} />
              <Typography variant="body2" color="textSecondary">
                {DateTime.fromISO(trip.startDate).toFormat('dd.MM.yyyy')} -{' '}
                {DateTime.fromISO(trip.endDate).toFormat('dd.MM.yyyy')}
              </Typography>
            </Box>

            <Button
              variant="text"
              sx={{ marginLeft: 'auto', color: '#primary' }}
            >
              Map Direction
            </Button>
          </Box>

          <Typography variant="h6">{t('description')}</Typography>
          <Box
            component="div"
            sx={{
              fontSize: isMobile ? '12px' : '16px',
              lineHeight: isMobile ? 1.2 : 1.4,
              '& p': {
                marginBottom: theme.spacing(1), // Reduced spacing
                color: 'rgba(0, 0, 0, 0.6)',
              },
              '& ul': {
                paddingLeft: theme.spacing(4),
                marginBottom: theme.spacing(0.5), // Reduced spacing
                color: 'rgba(0, 0, 0, 0.6)',
              },
              '& li': {
                marginBottom: theme.spacing(0.5), // Reduced spacing
                color: 'rgba(0, 0, 0, 0.6)',
              },
              '& a': {
                color: theme.palette.primary.main,
                textDecoration: 'underline',
                '&:hover': {
                  textDecoration: 'none',
                },
              },
              '& img': {
                maxWidth: '100%',
                height: 'auto',
                borderRadius: theme.shape.borderRadius,
                marginTop: theme.spacing(2),
              },
            }}
            dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
          />
        </Col>
      </Row>

      {/* Included and Excluded Services */}
      <Grid container spacing={isMobile ? 1 : 2}>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              border: '1px solid #e0e0e0',
              borderRadius: '8px',
              padding: 2,
              maxWidth: '500px',
              margin: '0 auto',
              backgroundColor: theme.palette.grey[200],
            }}
          >
            <Typography variant="h6" gutterBottom>
              What's included?
            </Typography>
            <Grid container>
              <Grid item xs={12} sm={6}>
                {trip.includedServices?.map((item, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: isMobile ? 0 : 1,
                    }}
                  >
                    <CheckIcon sx={{ color: 'green', marginRight: '8px' }} />
                    <Typography variant="subtitle2" sx={{ color: '#2c3e50' }}>
                      {item}
                    </Typography>
                  </Box>
                ))}
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              border: '1px solid #e0e0e0',
              borderRadius: '8px',
              padding: 2,
              maxWidth: '500px',
              margin: '0 auto',
              backgroundColor: theme.palette.grey[200],
            }}
          >
            <Typography variant="h6" gutterBottom>
              What's not included?
            </Typography>
            <Grid container>
              <Grid item xs={12} sm={6}>
                {trip.excludedServices?.map((item, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: isMobile ? 0 : 1,
                    }}
                  >
                    <CloseIcon sx={{ color: 'red', marginRight: '8px' }} />
                    <Typography variant="subtitle2" sx={{ color: '#2c3e50' }}>
                      {item}
                    </Typography>
                  </Box>
                ))}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>

      {/* Book Now Section */}
      <BookNowSection trip={trip} />
    </Container>
  );
};

export default TripDetail;
