import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { CircularProgress, Container, Alert } from "@mui/material";
import { TRIP_QUERY } from "../../graphql/trips";
import TripDetail from "../../components/TripDetail/TripDetail";
import { useAuth } from "../../contexts/AuthContext";
import { isAdminGuard } from "../../utils/isAdminGuard";
import { TripStatusEnum } from "../../generated/graphql";

const TripDetailPage: React.FC = () => {
  const { user } = useAuth();
  const { tripId } = useParams<{ tripId: string }>();
  const { data, loading, error } = useQuery(TRIP_QUERY, {
    variables: { id: tripId },
  });

  if (loading) {
    return (
      <Container sx={{ textAlign: "center", mt: 4 }}>
        <CircularProgress />
      </Container>
    );
  }

  if (data?.trip?.status !== TripStatusEnum.Active && !isAdminGuard(user)) {
    console.error("You are not authorized to view this trip.");
    return (
      <Container sx={{ mt: 4 }}>
        <Alert severity="error">
          You are not authorized to see this content.
        </Alert>
      </Container>
    );
  }

  if (error || !data?.trip)
    return (
      <Container sx={{ mt: 4 }}>
        <Alert severity="error">Error loading trip details.</Alert>
      </Container>
    );

  return <TripDetail trip={data.trip} />;
};

export default TripDetailPage;
