import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Divider,
  IconButton,
  Avatar,
  Card,
  CardContent,
  useTheme,
  CircularProgress,
  Alert,
  Grid2,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import HomeIcon from '@mui/icons-material/Home';
import CakeIcon from '@mui/icons-material/Cake';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { User, MembershipStatusEnum } from '../../generated/graphql';

interface MembershipReviewModalProps {
  open: boolean;
  user: User | null;
  onClose: () => void;
  onAccept: () => void;
  onReject: () => void;
}

const MembershipReviewModal: React.FC<MembershipReviewModalProps> = ({ open, user, onClose, onAccept, onReject }) => {
  const theme = useTheme();
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [loadingPdf, setLoadingPdf] = useState<boolean>(false);
  const [pdfError, setPdfError] = useState<string | null>(null);

  useEffect(() => {
    let isCancelled = false;

    if (open && user?.membership?.signedDocumentKey) {
      // Fetch the signed PDF document
      const fetchPdf = async () => {
        setLoadingPdf(true);
        setPdfError(null);

        try {
          const accessToken = localStorage.getItem('accessToken');
          if (!accessToken) {
            throw new Error('No access token found. Please log in.');
          }

          const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/pdf-upload/pdf/user/${user.id}`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          if (!response.ok) {
            throw new Error('Failed to fetch the signed document.');
          }

          const blob = await response.blob();
          const url = URL.createObjectURL(blob);
          if (!isCancelled) {
            setPdfUrl(url);
          }
        } catch (error: any) {
          if (!isCancelled) {
            setPdfError(error.message);
          }
        } finally {
          if (!isCancelled) {
            setLoadingPdf(false);
          }
        }
      };

      fetchPdf();
    } else {
      // Clean up when modal is closed or user changes
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl);
        setPdfUrl(null);
      }
    }

    // Cleanup
    return () => {
      isCancelled = true;
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl);
        setPdfUrl(null);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, user]);

  if (!user) return null;

  const { membership, imageUrl, roles } = user;

  if (!membership) {
    return (
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogContent>
          <Typography variant="h6">No membership data available.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  const { firstname, lastname, birthDate, phone, contactAddress, permanentAddress, status, createdAt } = membership;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      {/* Header with Title and Close Button */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: theme.spacing(2),
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
        }}
      >
        <Typography variant="h6">Review Membership Request</Typography>
        <IconButton onClick={onClose} sx={{ color: 'inherit' }} aria-label="close">
          <CloseIcon />
        </IconButton>
      </Box>

      {/* Content */}
      <DialogContent dividers>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(3) }}>
          {/* User Information */}
          <Card>
            <CardContent>
              {/* User Avatar and Basic Info */}
              <Box sx={{ display: 'flex', alignItems: 'center', gap: theme.spacing(2) }}>
                <Avatar
                  src={imageUrl || '/default-avatar.png'}
                  alt={`${firstname} ${lastname}`}
                  sx={{ width: 80, height: 80 }}
                />
                <Box>
                  <Typography variant="h5" gutterBottom>
                    {firstname && lastname ? `${firstname} ${lastname}` : 'No Name'}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Member ID: {user.id}
                  </Typography>
                </Box>
              </Box>
              <Divider sx={{ my: 2 }} />

              {/* Two-Column Grid Layout */}
              <Grid2 container spacing={2}>
                {/* Left Column */}

                <Grid2 size={{ xs: 12, md: 6 }}>
                  {/* Date of Birth */}
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <CakeIcon color="action" />
                    <Box>
                      <Typography variant="subtitle2">Date of Birth</Typography>
                      <Typography variant="body1">
                        {birthDate ? new Date(birthDate).toLocaleDateString() : 'N/A'}
                      </Typography>
                    </Box>
                  </Box>

                  {/* Phone Number */}
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 2 }}>
                    <PhoneIcon color="action" />
                    <Box>
                      <Typography variant="subtitle2">Phone Number</Typography>
                      <Typography variant="body1">{phone || 'N/A'}</Typography>
                    </Box>
                  </Box>

                  {/* Email */}
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 2 }}>
                    <EmailIcon color="action" />
                    <Box>
                      <Typography variant="subtitle2">Email</Typography>
                      <Typography variant="body1">{user.email || 'N/A'}</Typography>
                    </Box>
                  </Box>
                </Grid2>

                {/* Right Column */}
                <Grid2 size={{ xs: 12, md: 6 }}>
                  {/* Roles */}
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <AssignmentIndIcon color="action" />
                    <Box>
                      <Typography variant="subtitle2">Roles</Typography>
                      <Typography variant="body1">{roles?.join(', ') || 'USER'}</Typography>
                    </Box>
                  </Box>

                  {/* Contact Address */}
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 2 }}>
                    <HomeIcon color="action" />
                    <Box>
                      <Typography variant="subtitle2">Contact Address</Typography>
                      <Typography variant="body1">{contactAddress || 'N/A'}</Typography>
                    </Box>
                  </Box>

                  {/* Permanent Address */}
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 2 }}>
                    <HomeIcon color="action" />
                    <Box>
                      <Typography variant="subtitle2">Permanent Address</Typography>
                      <Typography variant="body1">{permanentAddress || 'N/A'}</Typography>
                    </Box>
                  </Box>
                </Grid2>
              </Grid2>
            </CardContent>
          </Card>

          {/* Membership Details */}
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Membership Request Details
              </Typography>
              <Divider sx={{ my: 2 }} />

              <Grid2 container spacing={2}>
                {/* Status */}
                <Grid2 size={{ xs: 12, md: 6 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <CheckCircleIcon
                      color={
                        status === MembershipStatusEnum.Active
                          ? 'success'
                          : status === MembershipStatusEnum.Pending
                            ? 'warning'
                            : 'error'
                      }
                    />
                    <Box>
                      <Typography variant="subtitle2">Status</Typography>
                      <Typography variant="body1">{status || 'N/A'}</Typography>
                    </Box>
                  </Box>
                </Grid2>

                {/* Requested At */}
                <Grid2 size={{ xs: 12, md: 6 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography variant="subtitle2">Requested At</Typography>
                    <Typography variant="body1">
                      {createdAt ? new Date(createdAt).toLocaleDateString() : 'N/A'}
                    </Typography>
                  </Box>
                </Grid2>
              </Grid2>
            </CardContent>
          </Card>

          {/* Signed Document Preview */}
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Signed Document
              </Typography>
              <Divider sx={{ my: 2 }} />
              {loadingPdf ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                  <CircularProgress />
                </Box>
              ) : pdfError ? (
                <Alert severity="error">{pdfError}</Alert>
              ) : pdfUrl ? (
                <Box sx={{ height: '500px' }}>
                  <iframe src={pdfUrl} title="Signed Document" width="100%" height="100%" />
                </Box>
              ) : (
                <Typography variant="body1">No signed document available.</Typography>
              )}
            </CardContent>
          </Card>
        </Box>
      </DialogContent>

      {/* Actions */}
      <DialogActions sx={{ padding: theme.spacing(2) }}>
        <Button onClick={onReject} color="error" variant="contained" startIcon={<CancelIcon />}>
          Reject membership
        </Button>
        <Button onClick={onAccept} color="success" variant="contained" startIcon={<CheckCircleIcon />}>
          Accept membership
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MembershipReviewModal;
