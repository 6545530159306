import React, { useState } from 'react';
import { Button, IconButton, Grid2, Typography, Divider } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import { DayItinerary } from '../../../generated/graphql';
import RichTextEditor from '../../common/RichTextEditor/RichTextEditor';
import './Itinerary.scss';
import ImageUploadTrip from '../../ImageUploadTrip/ImageUploadTrip';
import { Box } from '@mui/system';
import DeleteIcon from '@mui/icons-material/Delete';

// Define props for the ItineraryList component
interface ItineraryListProps {
  itineraryItems: DayItinerary[];
  onChange: (items: DayItinerary[]) => void;
}

const Itinerary: React.FC<ItineraryListProps> = ({ itineraryItems, onChange }) => {
  const [itinerary, setItinerary] = useState<DayItinerary[]>(itineraryItems);

  const addItineraryItem = () => {
    const newItem: DayItinerary = {
      description: '',
      photoUrls: [],
      createdAt: new Date().toISOString(),
      id: '',
      updatedAt: new Date().toISOString(),
    };
    setItinerary([...itinerary, newItem]); // Add new item to the array
    onChange(itinerary);
  };

  const removeItineraryItem = () => {
    const updatedItems = itinerary.filter((_, index) => index < itinerary.length - 1);
    setItinerary(updatedItems); // Update state with the filtered array
    onChange(itinerary);
  };

  const handleUpdateItem = (index: number, field: keyof DayItinerary, value: string) => {
    setItinerary((prevItems) => {
      let updatedItems = [...prevItems];
      if (field === 'photoUrls') {
        let updatedPhotoUrls = [value];
        if (updatedItems[index].photoUrls) {
          updatedPhotoUrls = [...updatedItems[index].photoUrls, value];
        }

        updatedItems[index] = { ...updatedItems[index], [field]: updatedPhotoUrls };
      } else if (field === 'description') {
        updatedItems[index] = { ...updatedItems[index], [field]: value };
      }
      onChange(updatedItems);
      return updatedItems;
    });
  };

  const handleImageDelete = (imageUrl: string, index: number) => {
    setItinerary((prevItems) => {
      let updatedItems = [...prevItems];
      if (updatedItems[index].photoUrls) {
        const updatedPhotoUrls = updatedItems[index].photoUrls.filter((item, _) => item !== imageUrl);
        updatedItems[index] = { ...updatedItems[index], photoUrls: updatedPhotoUrls };
      }
      onChange(updatedItems);
      return updatedItems;
    });
  };

  const deleteImage = async (imageUrl: string, index: number) => {
    const accessToken = localStorage.getItem('accessToken');
    const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/upload/image`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ imageUrl }),
    });

    if (!response.ok) {
      throw new Error('Failed to delete image');
    }

    handleImageDelete(imageUrl, index);
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Itinerary
      </Typography>

      {/* Input fields for adding a new itinerary item */}
      <Grid2 container spacing={2}>
        {itinerary &&
          itinerary.map((item, itemIndex) => (
            <Grid2 size={12} key={itemIndex} className="itinerary-element">
              <Typography variant="h6" gutterBottom>
                Day {itemIndex + 1}
              </Typography>
              <Grid2 container spacing={2}>
                <Grid2 size={{ xs: 12, md: 6 }}>
                  <Typography variant="subtitle1" gutterBottom>
                    Description
                  </Typography>
                  <RichTextEditor
                    content={item.description}
                    onChange={(desc: string) => handleUpdateItem(itemIndex, 'description', desc)}
                    index={itemIndex}
                  />
                </Grid2>
                <Grid2 size={{ xs: 12, md: 6 }}>
                  <Typography variant="subtitle1" gutterBottom>
                    Photos
                  </Typography>
                  <ImageUploadTrip
                    onUploadSuccess={(photoUrl: string) => handleUpdateItem(itemIndex, 'photoUrls', photoUrl)}
                    index={itemIndex}
                  />

                  <Box sx={{ mt: 1 }}>
                    <Divider variant="middle" component="div" />
                    <Grid2 container spacing={2}>
                      {item.photoUrls &&
                        item.photoUrls.map((url, urlIndex) => (
                          <Box key={urlIndex}>
                            <Box component="img" src={url} alt="Preview" sx={{ height: 100, mt: 1 }} />
                            <IconButton aria-label="delete" onClick={() => deleteImage(url, itemIndex)}>
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        ))}
                    </Grid2>
                  </Box>
                </Grid2>
              </Grid2>
            </Grid2>
          ))}
      </Grid2>

      <Grid2 container spacing={1} size={12} sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
        <Button
          startIcon={<Remove />}
          color="primary"
          variant="contained"
          className="icon-button"
          onClick={removeItineraryItem}
        >
          Remove day
        </Button>

        <Button
          startIcon={<Add />}
          color="primary"
          variant="contained"
          className="icon-button"
          onClick={addItineraryItem}
        >
          Add day
        </Button>
      </Grid2>
    </div>
  );
};

export default Itinerary;
