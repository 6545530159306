import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Container, Typography, Alert } from '@mui/material';
import { MEMBERSHIP_REQUEST_MUTATION } from '../../graphql/membership';
import MembershipModal from '../../components/Memberships/MembershipModal';

const MembershipRequestPage: React.FC = () => {
  const [openModal, setOpenModal] = useState(false);
  const [signedDocumentKey, setSignedDocumentKey] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const [requestMembership, { loading }] = useMutation(MEMBERSHIP_REQUEST_MUTATION, {
    onCompleted: () => {
      alert('Membership request sent!');
      // Reset state
      setSignedDocumentKey(null);
      setOpenModal(false);
    },
    onError: (err) => {
      setError(err.message);
    },
  });

  const handleAccept = (formData: any) => {
    if (!signedDocumentKey) {
      setError('Please upload the signed membership request PDF.');
      return;
    }

    requestMembership({
      variables: {
        data: {
          ...formData,
          signedDocumentKey,
        },
      },
    });
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" align="center" gutterBottom>
        Request Membership
      </Typography>
      {error && <Alert severity="error">{error}</Alert>}
      <Button variant="contained" color="primary" fullWidth onClick={() => setOpenModal(true)} disabled={loading}>
        Request Membership
      </Button>
      <MembershipModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        onAccept={handleAccept}
        setSignedDocumentKey={setSignedDocumentKey}
      />
    </Container>
  );
};

export default MembershipRequestPage;
