import { gql } from '@apollo/client';

export const USER_DATA_FRAGMENT = gql`
  fragment UserData on User {
    id
    email
    firstname
    lastname
    phone
    birthDate
    permanentAddress
    contactAddress
    createdAt
    role
    roles
    isEmailSubscribed
    exp
    level
    imageUrl
    membership {
      id
      status
      createdAt
      updatedAt
      firstname
      lastname
      birthDate
      permanentAddress
      contactAddress
      phone
      signedDocumentKey
    }
  }
`;

export const ME_QUERY = gql`
  query Me {
    me {
      ...UserData
    }
  }
  ${USER_DATA_FRAGMENT}
`;

export const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser($data: UpdateUserInput!) {
    updateUser(data: $data) {
      ...UserData
    }
  }
  ${USER_DATA_FRAGMENT}
`;

export const USERS_QUERY = gql`
  query Users(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $orderBy: UserOrder
    $filter: SearchUsersFilter
  ) {
    users(first: $first, after: $after, last: $last, before: $before, orderBy: $orderBy, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          ...UserData
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${USER_DATA_FRAGMENT}
`;

export const USER_QUERY = gql`
  query User($id: String!) {
    user(id: $id) {
      ...UserData
    }
  }
  ${USER_DATA_FRAGMENT}
`;

export const UPDATE_USER_ROLES_MUTATION = gql`
  mutation UpdateUserRoles($id: String!, $role: Role!, $action: String!) {
    updateUserRoles(id: $id, role: $role, action: $action) {
      ...UserData
    }
  }
  ${USER_DATA_FRAGMENT}
`;

export enum RoleAction {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
}

export const DELETE_USER_MUTATION = gql`
  mutation DeleteUser($id: String!) {
    deleteUser(id: $id) {
      id
    }
  }
`;
