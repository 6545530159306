import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Grid,
  CircularProgress,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Avatar,
} from '@mui/material';
import { useMutation } from '@apollo/client';
import { UpdateUserDocument, UpdateUserMutation, UpdateUserMutationVariables, User } from '../../generated/graphql';
import { SelectChangeEvent } from '@mui/material/Select';
import ImageUpload from '../ImageUpload/ImageUpload';

interface ProfileEditModalProps {
  open: boolean;
  onClose: () => void;
  user?: User | null;
}

const ProfileEditModal: React.FC<ProfileEditModalProps> = ({ open, onClose, user }) => {
  const [formData, setFormData] = useState({
    firstname: user?.firstname || '',
    lastname: user?.lastname || '',
    phone: user?.phone || '',
    birthDate: user?.birthDate || '',
    permanentAddress: user?.permanentAddress || '',
    contactAddress: user?.contactAddress || '',
    isEmailSubscribed: user?.isEmailSubscribed ?? true,
  });

  const [imageUrl, setImageUrl] = useState<string | null>(user?.imageUrl || null);

  const [formErrors, setFormErrors] = useState({
    firstname: false,
    lastname: false,
    phone: false,
    birthDate: false,
    permanentAddress: false,
  });

  const [isSaving, setIsSaving] = useState(false);

  const [updateUser] = useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, {
    onCompleted: () => {
      onClose();
    },
    onError: (error) => {
      console.error('Error updating profile:', error);
    },
  });

  useEffect(() => {
    if (user) {
      setFormData({
        firstname: user.firstname || '',
        lastname: user.lastname || '',
        phone: user.phone || '',
        birthDate: user.birthDate || '',
        permanentAddress: user.permanentAddress || '',
        contactAddress: user.contactAddress || '',
        isEmailSubscribed: user.isEmailSubscribed ?? true, // Handle explicitly
      });
      setImageUrl(user.imageUrl || null);
    }
  }, [user]);

  // Handle changes for TextField components
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle changes for the Select component
  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    const newValue = value === 'true';
    setFormData({ ...formData, [name as string]: newValue });
  };

  const validateFields = () => {
    const errors = {
      firstname: formData.firstname === '',
      lastname: formData.lastname === '',
      phone: formData.phone === '',
      birthDate: formData.birthDate === '',
      permanentAddress: formData.permanentAddress === '',
    };
    setFormErrors(errors);
    return !Object.values(errors).some((error) => error === true);
  };

  const handleSave = async () => {
    if (!validateFields()) {
      return;
    }

    setIsSaving(true);
    try {
      await updateUser({
        variables: {
          data: {
            firstname: formData.firstname,
            lastname: formData.lastname,
            birthDate: formData.birthDate,
            permanentAddress: formData.permanentAddress,
            contactAddress: formData.contactAddress,
            phone: formData.phone,
            isEmailSubscribed: formData.isEmailSubscribed,
            imageUrl: imageUrl,
          },
        },
      });
    } catch (error) {
      console.error('Error updating profile:', error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      aria-labelledby="edit-profile-dialog"
      PaperProps={{
        sx: {
          width: { xs: '100%', sm: 'auto' },
          m: { xs: 1, sm: 'auto' },
        },
      }}
    >
      <DialogTitle id="edit-profile-dialog">Edit Profile</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          {/* Profile Image */}
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Avatar
              src={imageUrl || '/images/default_avatar.png'}
              alt="Profile Image"
              sx={{ width: 100, height: 100, mx: 'auto', mb: 2 }}
            />
            <ImageUpload onUploadSuccess={(uploadedImageUrl) => setImageUrl(uploadedImageUrl)} />
          </Grid>
          {/* First Name */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="First Name"
              name="firstname"
              value={formData.firstname}
              onChange={handleInputChange}
              fullWidth
              required
              error={formErrors.firstname}
              helperText={formErrors.firstname && 'First name is required'}
            />
          </Grid>
          {/* Last Name */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Last Name"
              name="lastname"
              value={formData.lastname}
              onChange={handleInputChange}
              fullWidth
              required
              error={formErrors.lastname}
              helperText={formErrors.lastname && 'Last name is required'}
            />
          </Grid>
          {/* Phone */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Phone"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              fullWidth
              required
              error={formErrors.phone}
              helperText={formErrors.phone && 'Phone number is required'}
            />
          </Grid>
          {/* Birth Date */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Birth Date"
              name="birthDate"
              value={formData.birthDate}
              onChange={handleInputChange}
              fullWidth
              type="date"
              InputLabelProps={{ shrink: true }}
              required
              error={formErrors.birthDate}
              helperText={formErrors.birthDate && 'Birth date is required'}
            />
          </Grid>
          {/* Permanent Address */}
          <Grid item xs={12}>
            <TextField
              label="Permanent Address"
              name="permanentAddress"
              value={formData.permanentAddress}
              onChange={handleInputChange}
              fullWidth
              required
              error={formErrors.permanentAddress}
              helperText={formErrors.permanentAddress && 'Permanent address is required'}
            />
          </Grid>
          {/* Contact Address */}
          <Grid item xs={12}>
            <TextField
              label="Contact Address"
              name="contactAddress"
              value={formData.contactAddress}
              onChange={handleInputChange}
              fullWidth
              helperText="Optional"
            />
          </Grid>
          {/* Email Subscription */}
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="email-subscribed-label">Email Subscribed</InputLabel>
              <Select
                labelId="email-subscribed-label"
                name="isEmailSubscribed"
                value={formData.isEmailSubscribed ? 'true' : 'false'}
                onChange={handleSelectChange}
                label="Email Subscribed"
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
              <FormHelperText>Choose whether to receive email notifications</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', p: 2 }}>
        <Button onClick={onClose} color="secondary" disabled={isSaving}>
          Cancel
        </Button>
        <Box sx={{ position: 'relative' }}>
          <Button onClick={handleSave} color="primary" variant="contained" disabled={isSaving} sx={{ minWidth: 120 }}>
            {isSaving ? 'Saving...' : 'Save Changes'}
          </Button>
          {isSaving && (
            <CircularProgress
              size={24}
              sx={{
                color: 'primary.main',
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ProfileEditModal;
