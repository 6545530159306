import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Membership } from '../../../generated/graphql';

interface ConfirmationProps {
  formData: Partial<Membership>;
}

const Confirmation: React.FC<ConfirmationProps> = ({ formData }) => {
  const { t } = useTranslation();
  const status = formData.status;

  console.log('Confirmation formData:', formData);
  console.log('Confirmation status:', status);

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        {t('membership_request_submitted')}
      </Typography>
      <Typography variant="body1">
        {t('membership_request_status', { status: status || t('unknown_status') })}
      </Typography>
    </Box>
  );
};

export default Confirmation;
