// src/graphql/mutations.ts
import { gql } from '@apollo/client';

export const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    login(data: { email: $email, password: $password }) {
      accessToken
      refreshToken
      user {
        id
        email
        firstname
        lastname
        phone
        birthDate
        permanentAddress
        contactAddress
        createdAt
        role
        roles
      }
    }
  }
`;

export const SIGNUP_MUTATION = gql`
  mutation Signup($email: String!, $password: String!) {
    signup(data: { email: $email, password: $password }) {
      accessToken
      refreshToken
      user {
        id
        email
        firstname
        lastname
        phone
        birthDate
        permanentAddress
        contactAddress
        createdAt
        role
        roles
      }
    }
  }
`;
