import React, { FC, useEffect, useState, useCallback } from "react";
import { Box, Modal, IconButton, useTheme } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";

interface ImageGalleryModalProps {
  images: string[];
  open: boolean;
  onClose: () => void;
  initialIndex?: number;
}

const ImageGalleryModal: FC<ImageGalleryModalProps> = ({
  images,
  open,
  onClose,
  initialIndex = 0,
}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(initialIndex);

  const theme = useTheme();

  // Update the current image index when initialIndex changes
  useEffect(() => {
    setCurrentImageIndex(initialIndex);
  }, [initialIndex]);

  // Memoize the handlers to prevent unnecessary re-creations
  const handleNext = useCallback(() => {
    setCurrentImageIndex((prev) => (prev + 1) % images.length);
  }, [images.length]);

  const handlePrev = useCallback(() => {
    setCurrentImageIndex((prev) => (prev - 1 + images.length) % images.length);
  }, [images.length]);

  // Handle keyboard events without depending on handleNext and handlePrev
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.key) {
        case "ArrowRight":
          setCurrentImageIndex((prev) => (prev + 1) % images.length);
          break;
        case "ArrowLeft":
          setCurrentImageIndex(
            (prev) => (prev - 1 + images.length) % images.length
          );
          break;
        case "Escape":
          onClose();
          break;
        default:
          break;
      }
    };

    if (open) {
      window.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [open, images.length, onClose]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="image-gallery-modal"
      aria-describedby="image-gallery-modal-description"
      closeAfterTransition
    >
      <Box
        sx={{
          position: "fixed",
          inset: 0,
          bgcolor: "rgba(0, 0, 0, 0.9)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          outline: "none",
        }}
      >
        {/* Close Button */}
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            right: theme.spacing(2),
            top: theme.spacing(2),
            color: "#fff",
          }}
          aria-label="Close"
        >
          <CloseIcon fontSize="large" />
        </IconButton>

        {/* Navigation Buttons */}
        {images.length > 1 && (
          <>
            <IconButton
              onClick={handlePrev}
              sx={{
                position: "absolute",
                left: theme.spacing(2),
                color: "#fff",
              }}
              aria-label="Previous"
            >
              <ArrowBackIosNewIcon fontSize="large" />
            </IconButton>
            <IconButton
              onClick={handleNext}
              sx={{
                position: "absolute",
                right: theme.spacing(2),
                color: "#fff",
              }}
              aria-label="Next"
            >
              <ArrowForwardIosIcon fontSize="large" />
            </IconButton>
          </>
        )}

        {/* Current Image */}
        <Box
          component="img"
          src={images[currentImageIndex]}
          alt="" // Set to empty string if decorative
          sx={{
            maxHeight: "90vh",
            maxWidth: "90vw",
            objectFit: "contain",
          }}
        />
      </Box>
    </Modal>
  );
};

export default ImageGalleryModal;
