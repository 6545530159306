// components/EditableAccordion.tsx

import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";

interface EditableAccordionProps {
  label: string;
  items: string[];
  onItemsChange: (items: string[]) => void;
}

const EditableAccordion: React.FC<EditableAccordionProps> = ({
  label,
  items,
  onItemsChange,
}) => {
  const [inputValue, setInputValue] = useState("");

  const handleAddItem = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" && inputValue.trim()) {
      onItemsChange([...items, inputValue.trim()]);
      setInputValue("");
      event.preventDefault();
    }
  };

  const handleDeleteItem = (index: number) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    onItemsChange(newItems);
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {label}
      </AccordionSummary>
      <AccordionDetails>
        <TextField
          label="Add Item"
          fullWidth
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyPress={handleAddItem}
          helperText="Press Enter to add item"
        />
        <List>
          {items.map((item, index) => (
            <ListItem key={index} divider>
              <ListItemText primary={item} />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleDeleteItem(index)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export default EditableAccordion;
