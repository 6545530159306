import React, { useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  CircularProgress,
  Alert,
  Card,
  CardContent,
  Avatar,
  LinearProgress,
  Tooltip,
} from '@mui/material';
import ProfileEditModal from '../../components/User/ProfileEditModal';
import EditIcon from '@mui/icons-material/Edit';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import { useAuth } from '../../contexts/AuthContext';
import InfoIcon from '@mui/icons-material/Info';
import {
  loyaltyLevels,
  LoyaltyLevelToName,
} from '../../shared/loyalty/ProgramData';
import { useTranslation } from 'react-i18next';
import { LoyaltyLevel } from '../../generated/graphql';

const MePage: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { user, loading, error, refetch } = useAuth();
  const { t } = useTranslation();

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error">
        Error fetching profile data.
        <Button
          onClick={() => refetch()}
          variant="outlined"
          size="small"
          sx={{ ml: 1 }}
        >
          Retry
        </Button>
      </Alert>
    );
  }

  if (!user) {
    return <Alert severity="info">No profile data available.</Alert>;
  }

  const openEditModal = () => {
    setIsModalOpen(true);
  };

  // Find the current level index based on the user's level
  const currentLevelIndex = loyaltyLevels.findIndex(
    (lvl) => lvl.title === user.level
  );

  const currentLevel = loyaltyLevels[currentLevelIndex];
  const nextLevel = loyaltyLevels[currentLevelIndex + 1];

  let progressToNextLevel = 100;

  if (nextLevel) {
    progressToNextLevel =
      ((user.exp - currentLevel.expStart) /
        (nextLevel.expStart - currentLevel.expStart)) *
      100;
  } else {
    // User is at the highest level
    progressToNextLevel =
      ((user.exp - currentLevel.expStart) /
        (currentLevel.expEnd - currentLevel.expStart + 1)) *
      100;
  }

  // Ensure the progress value is between 0 and 100
  progressToNextLevel = Math.min(Math.max(progressToNextLevel, 0), 100);

  return (
    <Box
      sx={{
        p: { xs: 2, md: 3 },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {/* Profile Header */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
          width: '100%',
          maxWidth: 800,
          mb: 4,
        }}
      >
        <Avatar
          src={user?.imageUrl || '/images/default_avatar.png'}
          alt={`${user.firstname} ${user.lastname}`}
          sx={{ width: 100, height: 100, mr: { md: 4 }, mb: { xs: 2, md: 0 } }}
        />
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h4" gutterBottom>
            {user.firstname && user.lastname
              ? `${user.firstname} ${user.lastname}`
              : user.email}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {user.email}
          </Typography>
        </Box>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={openEditModal}
          startIcon={<EditIcon />}
          sx={{ mt: { xs: 2, md: 0 } }}
        >
          Edit Profile
        </Button>
      </Box>

      {/* Loyalty Program Section */}
      <Card sx={{ maxWidth: 800, width: '100%', mb: 4 }}>
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: 2,
            }}
          >
            <LoyaltyIcon color="primary" sx={{ mr: 1 }} />
            <Typography variant="h6">Loyalty Program</Typography>
            <Tooltip title="Learn more about our loyalty program">
              <InfoIcon color="action" sx={{ ml: 1, cursor: 'pointer' }} />
            </Tooltip>
          </Box>
          <Typography variant="body1">
            {t('level')}: <strong>{LoyaltyLevelToName[user.level]}</strong>
          </Typography>
          <Typography variant="body1" gutterBottom>
            Experience Points (EXP): <strong>{user.exp}</strong>
          </Typography>
          {nextLevel && (
            <>
              <Typography variant="body2" color="textSecondary">
                {user.exp} / {nextLevel.expStart} EXP to reach{' '}
                {LoyaltyLevelToName[nextLevel.title as LoyaltyLevel]}
              </Typography>
              <LinearProgress
                variant="determinate"
                value={progressToNextLevel}
                sx={{ height: 10, borderRadius: 5, mt: 1 }}
              />
            </>
          )}
          {!nextLevel && (
            <Typography variant="body2" color="textSecondary">
              You've reached the highest level!
            </Typography>
          )}
        </CardContent>
      </Card>

      {/* Profile Details */}
      <Card sx={{ maxWidth: 800, width: '100%' }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Profile Details
          </Typography>
          <Grid container spacing={2}>
            {/* First Name */}
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1">First Name</Typography>
              <Typography variant="body1">
                {user.firstname || 'No data'}
              </Typography>
            </Grid>
            {/* Last Name */}
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1">Last Name</Typography>
              <Typography variant="body1">
                {user.lastname || 'No data'}
              </Typography>
            </Grid>
            {/* Email Subscription */}
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1">Email Subscribed</Typography>
              <Typography variant="body1">
                {user.isEmailSubscribed ? 'Yes' : 'No'}
              </Typography>
            </Grid>
            {/* Phone */}
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1">Phone</Typography>
              <Typography variant="body1">{user.phone || 'No data'}</Typography>
            </Grid>
            {/* Birth Date */}
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1">Birth Date</Typography>
              <Typography variant="body1">
                {user.birthDate
                  ? new Date(user.birthDate).toLocaleDateString()
                  : 'No data'}
              </Typography>
            </Grid>
            {/* Permanent Address */}
            <Grid item xs={12}>
              <Typography variant="subtitle1">Permanent Address</Typography>
              <Typography variant="body1">
                {user.permanentAddress || 'No data'}
              </Typography>
            </Grid>
            {/* Contact Address */}
            <Grid item xs={12}>
              <Typography variant="subtitle1">Contact Address</Typography>
              <Typography variant="body1">
                {user.contactAddress || 'No data'}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Profile Edit Modal */}
      <ProfileEditModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        user={user}
      />
    </Box>
  );
};

export default MePage;
