import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  Button,
  TextField,
  Container,
  Typography,
  Alert,
  Tabs,
  Tab,
  Box,
  Divider,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LOGIN_MUTATION, SIGNUP_MUTATION } from '../../graphql/auth';

const AuthPage: React.FC = () => {
  const [tabIndex, setTabIndex] = useState(0); // 0 for login, 1 for signup
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  // For signup, we need confirmPassword
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const [login, { loading: loginLoading }] = useMutation(LOGIN_MUTATION, {
    onCompleted: (data) => {
      localStorage.setItem('accessToken', data.login.accessToken);
      localStorage.setItem('refreshToken', data.login.refreshToken);
      localStorage.setItem('user', JSON.stringify(data.login.user));
      navigate('/membership-dashboard');
    },
    onError: (error) => {
      setError(error.message);
    },
  });

  const [signup, { loading: signupLoading }] = useMutation(SIGNUP_MUTATION, {
    onCompleted: (data) => {
      localStorage.setItem('accessToken', data.signup.accessToken);
      localStorage.setItem('refreshToken', data.signup.refreshToken);
      localStorage.setItem('user', JSON.stringify(data.signup.user));
      navigate('/membership-dashboard');
    },
    onError: (error) => {
      setError(error.message);
    },
  });

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
    setError(null); // Clear error when switching tabs
    // Clear form fields
    setEmail('');
    setPassword('');
    setConfirmPassword('');
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (tabIndex === 0) {
      // Login
      login({ variables: { email, password } });
    } else {
      // Signup
      if (password !== confirmPassword) {
        setError('Passwords do not match');
        return;
      }
      signup({ variables: { email, password } });
    }
  };

  const handleGoogleLogin = () => {
    window.location.href = `${process.env.REACT_APP_BACKEND_HOST}/auth/google`;
  };

  return (
    <Container maxWidth="xs" sx={{ mt: 4 }}>
      <Box textAlign="center" mb={3}>
        <Typography variant="h4" component="h1">
          Welcome to Level Trevel
        </Typography>
        <Typography variant="subtitle1">
          {tabIndex === 0 ? 'Sign in to your account' : 'Create a new account'}
        </Typography>
      </Box>
      <Box sx={{ width: '100%' }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="fullWidth"
          centered
        >
          <Tab label="Login" />
          <Tab label="Sign Up" />
        </Tabs>
      </Box>
      <Box sx={{ padding: '1rem' }}>
        {error && <Alert severity="error">{error}</Alert>}
        <form onSubmit={handleSubmit} noValidate>
          <TextField
            fullWidth
            label="Email"
            margin="normal"
            variant="outlined"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            autoComplete="email"
          />
          <TextField
            fullWidth
            label="Password"
            type="password"
            margin="normal"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            autoComplete={tabIndex === 0 ? 'current-password' : 'new-password'}
          />
          {tabIndex === 1 && (
            <TextField
              fullWidth
              label="Confirm Password"
              type="password"
              margin="normal"
              variant="outlined"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              autoComplete="new-password"
            />
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            disabled={tabIndex === 0 ? loginLoading : signupLoading}
          >
            {tabIndex === 0 ? 'Login' : 'Sign Up'}
          </Button>
        </form>

        <Divider sx={{ my: 3 }}>OR</Divider>

        <Button
          fullWidth
          variant="outlined"
          onClick={handleGoogleLogin}
          sx={{
            textTransform: 'none',
            borderColor: '#dcdcdc',
            color: 'rgba(0,0,0,0.54)',
            backgroundColor: '#fff',
            '&:hover': {
              backgroundColor: '#f5f5f5',
            },
          }}
          startIcon={
            <img
              src="/images/google-logo.png"
              alt="Google Logo"
              style={{ width: '20px', height: '20px' }}
            />
          }
        >
          Sign {tabIndex === 0 ? 'in' : 'up'} with Google
        </Button>
      </Box>
    </Container>
  );
};

export default AuthPage;
