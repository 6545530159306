import { LoyaltyLevel } from '../../generated/graphql';

export const getNextLevelExp = (currentLevel: LoyaltyLevel): number => {
  const levels: Record<LoyaltyLevel, number> = {
    [LoyaltyLevel.Level_0]: 200,
    [LoyaltyLevel.Level_1]: 500,
    [LoyaltyLevel.Level_2]: 1000,
    [LoyaltyLevel.Level_3]: 2000,
    [LoyaltyLevel.Level_4]: 0, // Highest level
  };
  return levels[currentLevel] || 0;
};
