import React from "react";
import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Trip } from "../../generated/graphql";

interface BookNowSectionProps {
  trip: Trip;
}

const BookNowSection: React.FC<BookNowSectionProps> = ({ trip }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        mt: isMobile? 1: 4,
        p: isMobile? 1: 4,
        backgroundColor: theme.palette.grey[300],
        textAlign: "center",
        borderRadius: 2,
      }}
    >
      <Typography variant={isMobile? "h6": "h5"}  gutterBottom>
        Interested in this Trip?
      </Typography>
      <Typography variant={isMobile? "body2": "body1"} sx={{ mb: isMobile? 1: 2 }}>
        Click the button below to express your interest and we'll get back to
        you with more details!
      </Typography>
      <Button
        variant="contained"
        color="primary"
        size={isMobile? "medium": "large"}
        href={trip?.temporaryBookingLink ?? ""}
        target="_blank"
        rel="noopener noreferrer"
      >
        Book Now
      </Button>
    </Box>
  );
};

export default BookNowSection;
