import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';

interface DeleteUserDialogProps {
  isOpen: boolean;
  onClose: () => void;
  handleConfirmDeleteUser: () => void;
}

const DeleteUserDialog: React.FC<DeleteUserDialogProps> = ({ isOpen, onClose, handleConfirmDeleteUser }) => (
  <Dialog open={isOpen} onClose={onClose}>
    <DialogTitle>Delete User</DialogTitle>
    <DialogContent>
      <Typography variant="body1">Are you sure you want to delete this user? This action cannot be undone.</Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Cancel
      </Button>
      <Button onClick={handleConfirmDeleteUser} color="secondary">
        Delete
      </Button>
    </DialogActions>
  </Dialog>
);

export default DeleteUserDialog;
