import React from 'react';
import {
  Container,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  CardMedia,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Button,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import StarIcon from '@mui/icons-material/Star';
import { useTranslation } from 'react-i18next';
import { Parallax } from 'react-parallax';
import {
  earnExpMethods,
  loyaltyLevels,
  LoyaltyLevelToName,
} from '../../shared/loyalty/ProgramData';
import { LoyaltyLevel } from '../../generated/graphql';

const LoyaltyProgramPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* Hero Section with Parallax Effect */}
      <Parallax bgImage="/images/loyalty/goyamboka-beach.jpeg" strength={500}>
        <Box
          sx={{
            height: 400,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#fff',
            textAlign: 'center',
            backgroundColor: 'rgba(0,0,0,0.5)',
            backgroundBlendMode: 'darken',
          }}
        >
          <Container>
            <LoyaltyIcon color="primary" sx={{ fontSize: 60 }} />
            <Typography variant="h2" sx={{ fontWeight: 'bold' }}>
              {t('loyalty_program_title')}
            </Typography>
            <Typography variant="h5" sx={{ mt: 2 }}>
              {t('loyalty_program_subtitle')}
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              sx={{ mt: 4 }}
              href="/membership-dashboard"
            >
              {t('join_now')}
            </Button>
          </Container>
        </Box>
      </Parallax>

      <Container maxWidth="lg" sx={{ mt: 8, mb: 8 }}>
        {/* How to Earn EXP */}
        <Box sx={{ mb: 10 }}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ fontWeight: 'bold', textAlign: 'center' }}
          >
            {t('how_to_earn_exp_title')}
          </Typography>
          <Grid container spacing={4} sx={{ mt: 4 }}>
            {earnExpMethods.map((method, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card
                  sx={{
                    textAlign: 'center',
                    padding: 3,
                    backgroundColor: '#f5f5f5',
                  }}
                >
                  <CheckCircleIcon
                    color="primary"
                    sx={{ fontSize: 50, mb: 2 }}
                  />
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    {method.activity}
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    {method.exp}
                  </Typography>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Membership Levels */}
        <Box sx={{ mb: 10 }}>
          <Typography
            variant="h3"
            gutterBottom
            sx={{ fontWeight: 'bold', textAlign: 'center' }}
          >
            {t('membership_levels_title')}
          </Typography>
          <Grid container spacing={6} sx={{ mt: 4 }}>
            {loyaltyLevels.map((level, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card sx={{ height: '100%', borderRadius: '20px' }}>
                  <CardMedia
                    component="img"
                    height="220"
                    image={level.icon}
                    alt={level.title}
                    sx={{
                      borderTopLeftRadius: '20px',
                      borderTopRightRadius: '20px',
                    }}
                  />
                  <CardContent>
                    <Typography
                      variant="h5"
                      gutterBottom
                      sx={{ fontWeight: 'bold', textAlign: 'center' }}
                    >
                      {LoyaltyLevelToName[level.title as LoyaltyLevel]}
                    </Typography>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      sx={{ textAlign: 'center' }}
                    >
                      {t('exp_range')}: {level.exp}
                    </Typography>
                    <Divider sx={{ my: 2 }} />
                    <List dense>
                      {level.benefits.map((benefit, idx) => (
                        <ListItem key={idx}>
                          <ListItemIcon>
                            <StarIcon color="secondary" />
                          </ListItemIcon>
                          <ListItemText primary={benefit} />
                        </ListItem>
                      ))}
                    </List>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default LoyaltyProgramPage;
