import React, { useState, useEffect } from 'react';
import { Button, Box, CircularProgress, Alert, Typography } from '@mui/material';

interface PdfUploadProps {
  onUploadSuccess: (pdfKey: string) => void;
  onUploadError: (error: string) => void;
}

const PdfUpload: React.FC<PdfUploadProps> = ({ onUploadSuccess, onUploadError }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const [uploadError, setUploadError] = useState<string | null>(null);

  useEffect(() => {
    // Clean up the object URL when the component unmounts or when a new file is selected
    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [previewUrl]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUploadError(null);
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file.type !== 'application/pdf') {
        setUploadError('Only PDF files are allowed.');
        return;
      }
      setSelectedFile(file);
      const url = URL.createObjectURL(file);
      setPreviewUrl(url);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) return;

    setUploading(true);
    try {
      const formData = new FormData();
      formData.append('file', selectedFile);

      const accessToken = localStorage.getItem('accessToken');
      if (!accessToken) {
        throw new Error('No access token found. Please log in.');
      }

      const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/pdf-upload/pdf`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText);
      }

      const data = await response.json();
      const pdfKey = data.fileKey;

      onUploadSuccess(pdfKey);

      // Clean up
      setSelectedFile(null);
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
        setPreviewUrl(null);
      }
    } catch (error: any) {
      const errorMessage = error.message || 'Failed to upload PDF.';
      setUploadError(errorMessage);
      onUploadError(errorMessage);
    } finally {
      setUploading(false);
    }
  };

  const handleCancel = () => {
    setSelectedFile(null);
    if (previewUrl) {
      URL.revokeObjectURL(previewUrl);
      setPreviewUrl(null);
    }
  };

  return (
    <Box>
      {!selectedFile ? (
        <Button variant="contained" component="label">
          Select PDF File
          <input type="file" hidden onChange={handleFileChange} accept="application/pdf" />
        </Button>
      ) : (
        <>
          {uploadError && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {uploadError}
            </Alert>
          )}
          {previewUrl && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1">Preview of your document:</Typography>
              <Box sx={{ height: '500px', mt: 1 }}>
                <iframe src={previewUrl} title="PDF Preview" width="100%" height="100%" style={{ border: 'none' }} />
              </Box>
            </Box>
          )}
          <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
            <Button variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleUpload} disabled={uploading}>
              {uploading ? <CircularProgress size={24} /> : 'Upload'}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default PdfUpload;
