import React, { useState } from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';

interface ImageUploadProps {
  onUploadSuccess: (imageUrl: string) => void;
}

const ImageUpload: React.FC<ImageUploadProps> = ({ onUploadSuccess }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError(null);
    const file = event.target.files && event.target.files[0];
    if (file) {
      if (!file.type.match(/image\/(jpg|jpeg|png|gif|webp|heic|heif)/)) {
        setError('Only image files (jpg|jpeg|png|gif|webp|heic|heif) are allowed.');
        return;
      }
      if (file.size > 5 * 1024 * 1024) {
        setError('File size must be less than 5MB.');
        return;
      }

      setSelectedFile(file);

      // Create a preview URL
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      setError('No file selected');
      return;
    }

    setUploading(true);
    setError(null);

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const accessToken = localStorage.getItem('accessToken');

      const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/upload/image`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to upload image');
      }

      const data = await response.json();
      const imageUrl = data.imageUrl;

      onUploadSuccess(imageUrl);

      // Reset state
      setSelectedFile(null);
      setPreviewUrl(null);
    } catch (err) {
      console.error(err);
      setError('Failed to upload image');
    } finally {
      setUploading(false);
    }
  };

  return (
    <Box sx={{ textAlign: 'center' }}>
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id="image-upload-input"
        type="file"
        onChange={handleFileChange}
      />
      <label htmlFor="image-upload-input">
        <Button variant="outlined" component="span">
          Choose Image
        </Button>
      </label>
      {selectedFile && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="body2">{selectedFile.name}</Typography>
          {previewUrl && (
            <Box
              component="img"
              src={previewUrl}
              alt="Preview"
              sx={{ width: 100, height: 100, mt: 1, borderRadius: '50%' }}
            />
          )}
          <Button variant="contained" onClick={handleUpload} disabled={uploading} sx={{ mt: 2 }}>
            {uploading ? 'Uploading...' : 'Upload Image'}
          </Button>
        </Box>
      )}
      {uploading && (
        <Box sx={{ mt: 2 }}>
          <CircularProgress size={24} />
        </Box>
      )}
      {error && (
        <Typography variant="body2" color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default ImageUpload;
