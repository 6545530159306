import React, { useEffect } from 'react';
import { Box, Typography, Grid, useTheme, useMediaQuery } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PeopleIcon from '@mui/icons-material/People';
import PlaceIcon from '@mui/icons-material/Place';
import EuroIcon from '@mui/icons-material/Euro';
import { Trip } from '../../generated/graphql';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface TripSummaryProps {
  trip: Trip;
}

const TripSummary: React.FC<TripSummaryProps> = ({ trip }) => {
  const { t, i18n } = useTranslation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const getDaysBetweenDates = (date1: string, date2: string) => {
    const date1Millis = new Date(date1).getTime();
    const date2Millis = new Date(date2).getTime();
    const diffMillis = Math.abs(date1Millis - date2Millis);
    return Math.ceil(diffMillis / (1000 * 60 * 60 * 24));
  };

  let summaryItems = () => {
    return [
      {
        imagePath: '/images/icons/calendar.svg',
        label: 'Dates',
        value: `${getDaysBetweenDates(trip.startDate, trip.endDate)} ${t('days')}`,
      },
      {
        imagePath: '/images/icons/person.svg',
        label: 'Participants',
        value: `${trip.numberOfPeople}`,
      },
      {
        imagePath: '/images/icons/globe.svg',
        label: 'Location',
        value: `${trip.country}, ${trip.continent}`,
      },
      {
        imagePath: '/images/icons/euro.svg',
        label: 'Price',
        value: `€${trip.price.toFixed(2)}`,
      },
    ];
  };

  useEffect(() => {
    summaryItems();
  }, [i18n.language]);

  return (
    <Row className="text-center mb-3">
      {summaryItems().map((item, index) => (
        <Col key={index}>
          <Box>
            <img
              src={item.imagePath}
              alt="Logo dino"
              style={{ height: isMobile ? '35px' : '40px', objectFit: 'cover' }}
            />
            <Typography variant={isMobile ? 'subtitle2' : 'h5'}>
              {item.value}
            </Typography>
          </Box>
        </Col>
      ))}
    </Row>
  );
};

export default TripSummary;
