import React from 'react';
import { Box, Button, Typography, Alert, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PdfUpload from '../PdfUpload/PdfUpload';

interface UploadSignedDocumentProps {
  onBack: () => void;
  onNext: () => void;
  setSignedDocumentKey: (key: string) => void;
  signedDocumentKey: string | null;
  loading: boolean;
}

const UploadSignedDocument: React.FC<UploadSignedDocumentProps> = ({
  onBack,
  onNext,
  setSignedDocumentKey,
  signedDocumentKey,
  loading,
}) => {
  const { t } = useTranslation();
  const [uploadError, setUploadError] = React.useState<string | null>(null);

  const handleUploadSuccess = (pdfKey: string) => {
    setSignedDocumentKey(pdfKey);
    setUploadError(null);
  };

  const handleUploadError = (error: string) => {
    setUploadError(error);
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        {t('upload_signed_document_title')}
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        {t('upload_signed_document_description')}
      </Typography>
      <PdfUpload onUploadSuccess={handleUploadSuccess} onUploadError={handleUploadError} />
      {signedDocumentKey && (
        <Alert severity="success" sx={{ mt: 2 }}>
          {t('document_uploaded_successfully')}
        </Alert>
      )}
      {uploadError && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {uploadError}
        </Alert>
      )}
      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Button variant="outlined" onClick={onBack}>
          {t('back')}
        </Button>
        <Button variant="contained" color="primary" onClick={onNext} disabled={!signedDocumentKey || loading}>
          {loading ? <CircularProgress size={24} /> : t('submit_membership_request')}
        </Button>
      </Box>
    </Box>
  );
};

export default UploadSignedDocument;
