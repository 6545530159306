import React from 'react';
import {
  Container,
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  LinearProgress,
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
} from '@mui/material';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTranslation } from 'react-i18next';
import { LoyaltyLevel, User } from '../../../generated/graphql';
import { LoyaltyLevelToName } from '../../../shared/loyalty/ProgramData';
import { getNextLevelExp } from '../utils';

interface ActiveMembershipDashboardProps {
  user: User;
  handleCancelMembership: () => void;
}

const ActiveMembershipDashboard: React.FC<ActiveMembershipDashboardProps> = ({ user, handleCancelMembership }) => {
  const { t } = useTranslation();
  const loyaltyLevel = user?.level ?? LoyaltyLevel.Level_0;
  const loyaltyPoints = user?.exp ?? 0;
  const nextLevelExp = getNextLevelExp(loyaltyLevel);
  const progressToNextLevel = nextLevelExp > 0 ? (loyaltyPoints / nextLevelExp) * 100 : 100;
  const upcomingTrips: any[] = []; // TODO Replace with actual data
  const pastTrips: any[] = []; // TODO Replace with actual data

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      {/* Header */}
      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <Avatar src={user?.imageUrl || '/default-avatar.png'} sx={{ width: 100, height: 100, mx: 'auto', mb: 2 }} />
        <Typography variant="h5" gutterBottom>
          {t('welcome')}, {user?.firstname ?? t('guest')}!
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {t('member_since')} {new Date(user?.membership?.createdAt).toLocaleDateString()}
        </Typography>
      </Box>

      {/* Dashboard Content */}
      <Grid container spacing={3}>
        {/* Membership Info */}
        <Grid item xs={12} sm={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {t('membership_information_title')}
              </Typography>
              <Typography variant="body1">
                {t('status')}: {t('active')}
              </Typography>
              <Typography variant="body1">
                {t('member_id')}: {user?.membership?.id}
              </Typography>
              <Button variant="outlined" color="secondary" fullWidth onClick={handleCancelMembership} sx={{ mt: 2 }}>
                {t('cancel_membership_button')}
              </Button>
            </CardContent>
          </Card>
        </Grid>

        {/* Loyalty Program */}
        <Grid item xs={12} sm={6}>
          <Card>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <LoyaltyIcon color="primary" sx={{ mr: 1 }} />
                <Typography variant="h6">{t('loyalty_program')}</Typography>
              </Box>
              <Typography variant="body1">
                {t('level')}: <strong>{LoyaltyLevelToName[loyaltyLevel]}</strong>
              </Typography>
              <Typography variant="body1">
                {t('experience_points')}: <strong>{loyaltyPoints}</strong>
              </Typography>
              {nextLevelExp > 0 && (
                <>
                  <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                    {t('progress_to_next_level')}
                  </Typography>
                  <LinearProgress
                    variant="determinate"
                    value={progressToNextLevel}
                    sx={{ height: 10, borderRadius: 5, mt: 1 }}
                  />
                  <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                    {loyaltyPoints} / {nextLevelExp} EXP
                  </Typography>
                </>
              )}
              {nextLevelExp === 0 && (
                <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                  {t('highest_level_reached')}
                </Typography>
              )}
              <Button variant="outlined" color="primary" fullWidth href="/loyalty" sx={{ mt: 2 }}>
                {t('learn_more')}
              </Button>
            </CardContent>
          </Card>
        </Grid>

        {/* Upcoming Trips */}
        <Grid item xs={12} sm={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {t('upcoming_trips')}
              </Typography>
              {upcomingTrips.length === 0 ? (
                <>
                  <Typography variant="body1">{t('no_upcoming_trips')}</Typography>
                  <Button variant="contained" color="primary" sx={{ mt: 2 }} href="/trips">
                    {t('browse_trips_button')}
                  </Button>
                </>
              ) : (
                // Render list of upcoming trips
                upcomingTrips.map((trip: any) => (
                  <Typography key={trip.id} variant="body1">
                    {trip.name} - {trip.date}
                  </Typography>
                ))
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* Past Trips */}
        <Grid item xs={12} sm={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {t('past_trips')}
              </Typography>
              {pastTrips.length === 0 ? (
                <Typography variant="body1">{t('no_past_trips')}</Typography>
              ) : (
                // Render list of past trips
                pastTrips.map((trip: any) => (
                  <Typography key={trip.id} variant="body1">
                    {trip.name} - {trip.date}
                  </Typography>
                ))
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* Membership Benefits */}
        <Grid item xs={12} sm={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {t('membership_benefits_title')}
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <CheckCircleIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={t('membership_benefit_exclusive_trips')} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckCircleIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={t('membership_benefit_loyalty_program')} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckCircleIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={t('membership_benefit_community')} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckCircleIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={t('membership_benefit_discounts')} />
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ActiveMembershipDashboard;
