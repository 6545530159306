// src/contexts/AuthContext.tsx

import React, { createContext, useContext, useState, useEffect } from "react";
import { User, MeDocument } from "../generated/graphql";
import { useQuery } from "@apollo/client";

interface AuthContextProps {
  user: User | null;
  loading: boolean;
  error: any;
  refetch: () => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextProps>({
  user: null,
  loading: false,
  error: null,
  refetch: () => {},
  logout: () => {},
});

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const { data, loading, error, refetch } = useQuery(MeDocument, {
    fetchPolicy: "network-only",
    onError: (err) => {
      console.info("User is not authenticated");
    },
  });

  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    if (data && data.me) {
      setUser(data.me);
    } else {
      setUser(null);
    }
  }, [data]);

  const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("user");
    // Optionally reset Apollo Client cache
    // client.resetStore();
    setUser(null);
    console.log("Logged out");
  };

  return (
    <AuthContext.Provider value={{ user, loading, error, refetch, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
