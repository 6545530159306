import React from 'react';
import { Box, Tabs, Tab } from '@mui/material';

interface UserTabsProps {
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const UserTabs: React.FC<UserTabsProps> = ({ value, onChange }) => {
  const a11yProps = (index: number) => ({
    id: `user-management-tab-${index}`,
    'aria-controls': `user-management-tabpanel-${index}`,
  });

  return (
    <Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider', mb: 3 }}>
      <Tabs value={value} onChange={onChange} aria-label="user management tabs">
        <Tab label="All Users" {...a11yProps(0)} />
        <Tab label="Pending Memberships" {...a11yProps(1)} />
      </Tabs>
    </Box>
  );
};

export default UserTabs;
