import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Box,
  TextField,
  FormControlLabel,
  Switch,
  Alert,
} from '@mui/material';
import { Formik, Form, Field, FieldProps } from 'formik';
import { z } from 'zod';
import PdfUpload from './PdfUpload/PdfUpload';

const initialValues = {
  firstname: '',
  lastname: '',
  birthDate: '',
  permanentAddress: '',
  contactAddress: '',
  phone: '',
};

interface MembershipModalProps {
  open: boolean;
  onClose: () => void;
  onAccept: (formData: typeof initialValues) => void;
  setSignedDocumentKey: (key: string) => void;
}

const validationSchema = z.object({
  firstname: z.string().min(1, 'First name is required'),
  lastname: z.string().min(1, 'Last name is required'),
  birthDate: z.string().min(1, 'Birthdate is required'),
  permanentAddress: z.string().min(1, 'Permanent address is required'),
  contactAddress: z.string().min(1, 'Contact address is required'),
  phone: z.string().min(1, 'Phone number is required'),
});

const validateForm = (values: typeof initialValues) => {
  try {
    validationSchema.parse(values);
    return {};
  } catch (error) {
    const formErrors: { [key: string]: string } = {};
    if (error instanceof z.ZodError) {
      error.errors.forEach((err) => {
        if (err.path.length > 0) {
          formErrors[err.path[0]] = err.message;
        }
      });
    }
    return formErrors;
  }
};

const MembershipModal: React.FC<MembershipModalProps> = ({ open, onClose, onAccept, setSignedDocumentKey }) => {
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [pdfUploaded, setPdfUploaded] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = (values: typeof initialValues) => {
    if (!acceptedTerms) {
      setError('You must accept the terms and conditions.');
      return;
    }

    if (!pdfUploaded) {
      setError('You must upload the signed membership request PDF.');
      return;
    }

    onAccept(values);
    onClose();
  };

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAcceptedTerms(event.target.checked);
  };

  const handlePdfUploadSuccess = (pdfKey: string) => {
    setSignedDocumentKey(pdfKey);
    setPdfUploaded(true);
    setError(null);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Request Membership</DialogTitle>
      <DialogContent dividers>
        <Box>
          <Typography variant="body1" paragraph>
            Elektronická žiadosť o členstvo v občianskom združení: Level trevel o.z.
          </Typography>
        </Box>
        <Formik initialValues={initialValues} validate={validateForm} onSubmit={handleSubmit}>
          {({ isSubmitting, touched, errors }) => (
            <Form>
              <Box mb={2}>
                <Field name="firstname">
                  {({ field }: FieldProps) => (
                    <TextField
                      {...field}
                      label="First Name"
                      fullWidth
                      error={touched.firstname && Boolean(errors.firstname)}
                      helperText={touched.firstname && errors.firstname}
                      margin="normal"
                    />
                  )}
                </Field>
                <Field name="lastname">
                  {({ field }: FieldProps) => (
                    <TextField
                      {...field}
                      label="Last Name"
                      fullWidth
                      error={touched.lastname && Boolean(errors.lastname)}
                      helperText={touched.lastname && errors.lastname}
                      margin="normal"
                    />
                  )}
                </Field>
                <Field name="birthDate">
                  {({ field }: FieldProps) => (
                    <TextField
                      {...field}
                      label="Birth Date"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      error={touched.birthDate && Boolean(errors.birthDate)}
                      helperText={touched.birthDate && errors.birthDate}
                      margin="normal"
                    />
                  )}
                </Field>
                <Field name="permanentAddress">
                  {({ field }: FieldProps) => (
                    <TextField
                      {...field}
                      label="Permanent Address"
                      fullWidth
                      error={touched.permanentAddress && Boolean(errors.permanentAddress)}
                      helperText={touched.permanentAddress && errors.permanentAddress}
                      margin="normal"
                    />
                  )}
                </Field>
                <Field name="contactAddress">
                  {({ field }: FieldProps) => (
                    <TextField
                      {...field}
                      label="Contact Address"
                      fullWidth
                      error={touched.contactAddress && Boolean(errors.contactAddress)}
                      helperText={touched.contactAddress && errors.contactAddress}
                      margin="normal"
                    />
                  )}
                </Field>
                <Field name="phone">
                  {({ field }: FieldProps) => (
                    <TextField
                      {...field}
                      label="Phone Number"
                      fullWidth
                      error={touched.phone && Boolean(errors.phone)}
                      helperText={touched.phone && errors.phone}
                      margin="normal"
                    />
                  )}
                </Field>
              </Box>

              {/* Include PdfUpload Component */}
              <Box mt={2}>
                <Typography variant="h6" gutterBottom>
                  Upload Signed Membership Request PDF
                </Typography>
                <PdfUpload onUploadSuccess={handlePdfUploadSuccess} />
              </Box>

              <Box mt={3}>
                {/* Terms and conditions text */}
                {/* ... (keep your existing terms and conditions text here) ... */}

                <FormControlLabel
                  control={<Switch checked={acceptedTerms} onChange={handleToggleChange} color="primary" required />}
                  label="I agree to the terms and conditions"
                  sx={{ marginBottom: 2 }}
                />
              </Box>

              {error && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  {error}
                </Alert>
              )}

              <DialogActions>
                <Button onClick={onClose} color="secondary">
                  Cancel
                </Button>
                <Button type="submit" color="primary" variant="contained" disabled={isSubmitting}>
                  Submit Request
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default MembershipModal;
