import React, { memo } from 'react';
import { TextField, Box } from '@mui/material';

interface SearchBarProps {
  searchInput: string;
  onSearchInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  searchInputRef: React.RefObject<HTMLInputElement>;
}

const SearchBar: React.FC<SearchBarProps> = ({ searchInput, onSearchInputChange, searchInputRef }) => {
  return (
    <Box mb={3}>
      <TextField
        inputRef={searchInputRef}
        label="Search Users"
        variant="outlined"
        value={searchInput}
        onChange={onSearchInputChange}
        fullWidth
      />
    </Box>
  );
};

export default memo(SearchBar);
