import React from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import {
  Container,
  Typography,
  CircularProgress,
  Alert,
  Card,
  CardContent,
} from "@mui/material";
import { MEMBERSHIP_QUERY } from "../../graphql/membership";

const MembershipDetailPage: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const { data, loading, error } = useQuery(MEMBERSHIP_QUERY, {
    variables: { userId },
  });

  if (loading) return <CircularProgress />;
  if (error)
    return <Alert severity="error">Error loading membership data.</Alert>;

  const membership = data?.membership;

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        Membership Details
      </Typography>
      {membership ? (
        <Card>
          <CardContent>
            <Typography variant="h6">
              User: {membership.user.firstname} {membership.user.lastname}
            </Typography>
            <Typography>Status: {membership.status}</Typography>
            <Typography>Role: {membership.role}</Typography>
            <Typography>
              Approved By: {membership.approvedBy?.firstname}{" "}
              {membership.approvedBy?.lastname}
            </Typography>
            <Typography>
              Requested At:{" "}
              {new Date(membership.createdAt).toLocaleDateString()}
            </Typography>
          </CardContent>
        </Card>
      ) : (
        <Alert severity="info">No membership details available.</Alert>
      )}
    </Container>
  );
};

export default MembershipDetailPage;
