import React from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import './RichTextEditor.scss';
import MenuBar from './MenuBar';

interface RichTextEditorProps {
  content: string; // Initial content prop
  onChange: (value: string, index: number) => void; // Function to handle content changes
  index?: number;
}

// Define the RichTextEditor component
const RichTextEditor: React.FC<RichTextEditorProps> = ({ content, onChange, index = 0 }) => {
  // Initialize the editor with StarterKit
  const editor = useEditor({
    extensions: [
      StarterKit.configure(),
    ],
    content,
    onUpdate: ({ editor }) => {
      // Call the onChange prop with the updated HTML content
      onChange(editor.getHTML(), index);
    },
  })

  // Clean up editor when component unmounts
  if (!editor) {
    return null;
  }

  return (
    <div>
      {/* Editor Content */}
      <MenuBar editor={editor}/>
      <EditorContent editor={editor} />
    </div>
  );
};

export default RichTextEditor;
