import React from 'react';
import { Container, Typography, Box, Button, Grid, Alert, Stepper, Step, StepLabel } from '@mui/material';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';
import { Membership } from '../../../generated/graphql';

interface PendingMembershipProps {
  membership: Membership;
}

const PendingMembership: React.FC<PendingMembershipProps> = ({ membership }) => {
  const { t } = useTranslation();

  const submissionDate = new Date(membership.createdAt).toLocaleDateString();
  const estimatedApprovalTime = t('membership_estimated_approval_time_value');

  const handleContactSupport = () => {
    window.location.href = '/contact-support';
  };

  const steps = [t('membership_step_submission'), t('membership_step_review'), t('membership_step_approval')];

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <HourglassEmptyIcon color="primary" sx={{ fontSize: 80 }} />
        <Typography variant="h4" gutterBottom>
          {t('membership_status_pending')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('membership_pending_description')}
        </Typography>
        <Alert severity="info" sx={{ mt: 2 }}>
          {t('membership_pending_additional_info')}
        </Alert>
        <Stepper activeStep={1} alternativeLabel sx={{ mt: 4 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Grid container spacing={4}>
        {/* Submission Details */}
        <Grid item xs={12} md={6}>
          <Typography variant="h5" gutterBottom>
            {t('membership_submission_details_title')}
          </Typography>
          <Typography variant="body1" paragraph>
            {t('membership_submission_date')}: {submissionDate}
          </Typography>
          <Typography variant="body1" paragraph>
            {t('membership_estimated_approval_time')}: {estimatedApprovalTime}
          </Typography>
          <Typography variant="body1" paragraph>
            {t('membership_next_steps')}
          </Typography>
        </Grid>
        {/* Support Information */}
        <Grid item xs={12} md={6}>
          <Typography variant="h5" gutterBottom>
            {t('membership_support_title')}
          </Typography>
          <Typography variant="body1" paragraph>
            {t('membership_support_description')}
          </Typography>
          <Button variant="contained" color="primary" startIcon={<InfoIcon />} onClick={handleContactSupport}>
            {t('membership_contact_support_button')}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PendingMembership;
