import React, { useState } from 'react';
import { Container, Typography, Grid, List, ListItem, ListItemIcon, ListItemText, Button, Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import { useTranslation } from 'react-i18next';
import MultiStepForm from '../MultiStepForm/MultiStepForm';

const NoMembership: React.FC = () => {
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);

  const handleRequestMembership = () => {
    setShowForm(true);
  };

  if (showForm) {
    return (
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Typography variant="h4" align="center" gutterBottom>
          {t('membership_request')}
        </Typography>
        <MultiStepForm />
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h3" align="center" gutterBottom>
        {t('membership_required_title')}
      </Typography>
      <Typography variant="body1" align="center" paragraph>
        {t('membership_required_description')}
      </Typography>
      <Grid container spacing={4} sx={{ mt: 2 }}>
        {/* Benefits Section */}
        <Grid item xs={12} sm={6}>
          <Typography variant="h5" gutterBottom>
            {t('membership_benefits_title')}
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary={t('membership_benefit_exclusive_trips')} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary={t('membership_benefit_loyalty_program')} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary={t('membership_benefit_community')} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary={t('membership_benefit_discounts')} />
            </ListItem>
          </List>
        </Grid>
        {/* Loyalty Program Section */}
        <Grid item xs={12} sm={6}>
          <Typography variant="h5" gutterBottom>
            {t('loyalty_program_title')}
          </Typography>
          <Typography variant="body1" paragraph>
            {t('loyalty_program_description')}
          </Typography>
          <Button variant="outlined" color="primary" startIcon={<LoyaltyIcon />} href="/loyalty">
            {t('learn_more')}
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <Button variant="contained" color="primary" size="large" onClick={handleRequestMembership}>
          {t('request_membership_button')}
        </Button>
      </Box>
    </Container>
  );
};

export default NoMembership;
