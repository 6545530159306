// src/components/Trips/EditTripForm.tsx

import React, { useEffect, useState } from 'react';
import {
  TextField,
  Typography,
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid2,
  Divider,
  IconButton,
} from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import { CREATE_TRIP_MUTATION, TRIP_QUERY, UPDATE_TRIP_MUTATION } from '../../graphql/trips';
import { ContinentEnum, DayItinerary, Trip } from '../../generated/graphql';
import EditableAccordion from './EditableAccordion';
// import { useAuth } from "../../contexts/AuthContext";
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import RichTextEditor from '../common/RichTextEditor/RichTextEditor';
import Itinerary from './EditTripComponents/Itinerary';
import ImageUploadTrip from '../ImageUploadTrip/ImageUploadTrip';
import DeleteIcon from '@mui/icons-material/Delete';

interface EditTripFormProps {
  trip: Trip;
  isCreatingTrip: boolean;
}

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  temporaryBookingLink: Yup.string().url('Must be a valid URL').optional(),
  description: Yup.string().required('Description is required'),
  startDate: Yup.date().required('Start date is required'),
  endDate: Yup.date()
    .required('End date is required')
    .min(Yup.ref('startDate'), 'End date cannot be before start date'),
  numberOfPeople: Yup.number().min(1, 'At least one person').required('Number of people is required'),
  price: Yup.number().min(0, 'Price should be positive').required('Price is required'),
  country: Yup.string().required('Country is required'),
  images: Yup.array().min(4, 'At least four images are required'),
  includedServices: Yup.array().min(1, 'At least one included service is required'),
  excludedServices: Yup.array().min(1, 'At least one excluded service is required'),
  continent: Yup.string().oneOf(Object.values(ContinentEnum), 'Invalid continent').required('Continent is required'),
  itinerary: Yup.array().min(1, 'At least one excluded service is required'),
});

const EditTripForm: React.FC<EditTripFormProps> = ({ trip, isCreatingTrip }) => {
  const navigate = useNavigate();

  const [updateTrip, { loading }] = useMutation(UPDATE_TRIP_MUTATION, {
    refetchQueries: [
      {
        query: TRIP_QUERY,
        variables: { id: trip.id },
      },
    ],
  });
  const [createTrip, {}] = useMutation(CREATE_TRIP_MUTATION);

  const [imageUrls, setImageUrls] = useState<string[]>(trip.images!);

  const handleUpdate = async (values: any) => {
    for (let dayItinerar of values.itinerary) {
      delete dayItinerar['__typename'];
    }
    try {
      await updateTrip({
        variables: {
          id: trip.id,
          data: {
            ...values,
            // guidesIds: [user?.id], // TODO
            images: imageUrls,
          },
        },
      });
      console.log('Trip updated successfully');

      navigate(`/trip/${trip.id}`, { replace: true });
    } catch (err: any) {
      console.error('Error updating trip', err);
      // Optionally, set an error state to display
    }
  };

  const handleCreate = async (values: any) => {
    try {
      const response = await createTrip({
        variables: {
          input: {
            ...values,
            // guidesIds: [user?.id],
            images: imageUrls,
          },
        },
      });

      const tripId = response.data.createTrip.id;
      navigate(`/trip/${tripId}`, { replace: true });
    } catch (err: any) {
      console.error('Error creating trip', err);
    }
  };

  const pendingString = isCreatingTrip ? 'Creating...' : 'Updating...';
  const buttonString = isCreatingTrip ? 'Create Trip' : 'Update Trip';

  // console.log('TEMP', trip.itinerary);
  return (
    <Formik
      initialValues={{
        title: trip.title || '',
        description: trip.description || '',
        temporaryBookingLink: trip.temporaryBookingLink || '',
        startDate: DateTime.fromISO(trip.startDate),
        endDate: DateTime.fromISO(trip.endDate),
        numberOfPeople: trip.numberOfPeople || 1,
        price: trip.price || 0,
        country: trip.country || '',
        images: trip.images || [],
        includedServices: trip.includedServices || [],
        excludedServices: trip.excludedServices || [],
        continent: trip.continent || '',
        itinerary: trip.itinerary || [{ description: '', photoUrls: [] }],
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting, setStatus }) => {
        if (isCreatingTrip) {
          handleCreate(values)
            .then(() => {
              setSubmitting(false);
              setStatus({ success: true });
            })
            .catch((error) => {
              setSubmitting(false);
              setStatus({ success: false, message: error.message });
            });
        } else {
          handleUpdate(values)
            .then(() => {
              setSubmitting(false);
              setStatus({ success: true });
            })
            .catch((error) => {
              setSubmitting(false);
              setStatus({ success: false, message: error.message });
            });
        }
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, setFieldValue, isSubmitting, status }) => {
        const handleDeleteImage = async (imageUrl: string) => {
          const accessToken = localStorage.getItem('accessToken');
          const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/upload/image`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({ imageUrl }),
          });

          if (!response.ok) {
            throw new Error('Failed to delete image');
          }

          const newImageUrls = imageUrls.filter((url) => url !== imageUrl);
          setImageUrls(newImageUrls);
          setFieldValue('images', newImageUrls);
        };

        const handleChangeStartDate = (date: DateTime | null) => {
          setFieldValue('startDate', date);
          if (values.endDate && date && date > values.endDate) {
            setFieldValue('endDate', null);
          }
        };

        const handleChangeDesc = (content: string) => {
          setFieldValue('description', content);
        };

        const handleItinerary = (itinerary: DayItinerary[]) => {
          setFieldValue('itinerary', itinerary);
        };

        const handleAddImageUrl = (newUrl: string) => {
          setImageUrls((oldUrls) => {
            const newUrls = [...oldUrls, newUrl];
            setFieldValue('images', newUrls);
            return newUrls;
          });
        };

        return (
          <Form>
            <Grid2 container spacing={3}>
              {/* Trip Title */}
              <Grid2 size={12}>
                <TextField
                  label="Trip Title"
                  name="title"
                  fullWidth
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.title && Boolean(errors.title)}
                  helperText={touched.title && errors.title}
                />
              </Grid2>

              {/* Description */}
              <Grid2 size={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Description
                </Typography>
                <RichTextEditor content={values.description} onChange={handleChangeDesc} />
              </Grid2>
              {/* <Grid2 size={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Description
                </Typography>
                <ReactQuill
                  value={values.description}
                  onChange={(content) => setFieldValue('description', content)}
                  modules={modules}
                  formats={formats}
                  theme="snow"
                />
                {touched.description && errors.description && (
                  <Typography variant="caption" color="error">
                    {errors.description}
                  </Typography>
                )}
              </Grid2> */}

              {/* Temporary Booking Link */}
              <Grid2 size={12}>
                <TextField
                  label="Temporary Booking Link"
                  name="temporaryBookingLink"
                  fullWidth
                  value={values.temporaryBookingLink}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.temporaryBookingLink && Boolean(errors.temporaryBookingLink)}
                  helperText={touched.temporaryBookingLink && errors.temporaryBookingLink}
                />
              </Grid2>

              {/* Start Date */}
              <Grid2 size={{ xs: 12, sm: 6 }}>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <DatePicker
                    label="Start Date"
                    name="startDate"
                    onChange={(date: DateTime | null) => handleChangeStartDate(date)}
                    value={values.startDate}
                    sx={{ width: '100%' }}
                  />
                </LocalizationProvider>
              </Grid2>

              {/* End Date */}
              <Grid2 size={{ xs: 12, sm: 6 }}>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <DatePicker
                    label="End Date"
                    name="endDate"
                    onChange={(date: DateTime | null) => setFieldValue('endDate', date)}
                    minDate={values.startDate}
                    value={values.endDate}
                    sx={{ width: '100%' }}
                  />
                </LocalizationProvider>
              </Grid2>

              {/* Number of People */}
              <Grid2 size={{ xs: 12, sm: 6 }}>
                <TextField
                  label="Number of People"
                  name="numberOfPeople"
                  type="number"
                  fullWidth
                  value={values.numberOfPeople}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.numberOfPeople && Boolean(errors.numberOfPeople)}
                  helperText={touched.numberOfPeople && errors.numberOfPeople}
                  InputProps={{ inputProps: { min: 1 } }}
                />
              </Grid2>

              {/* Price */}
              <Grid2 size={{ xs: 12, sm: 6 }}>
                <TextField
                  label="Price (€)"
                  name="price"
                  type="number"
                  fullWidth
                  value={values.price}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.price && Boolean(errors.price)}
                  helperText={touched.price && errors.price}
                  InputProps={{ inputProps: { min: 0, step: '0.01' } }}
                />
              </Grid2>

              {/* Country */}
              <Grid2 size={{ xs: 12, sm: 6 }}>
                <TextField
                  label="Country"
                  name="country"
                  fullWidth
                  value={values.country}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.country && Boolean(errors.country)}
                  helperText={touched.country && errors.country}
                />
              </Grid2>

              {/* Continent */}
              <Grid2 size={{ xs: 12, sm: 6 }}>
                <FormControl fullWidth error={touched.continent && Boolean(errors.continent)}>
                  <InputLabel id="continent-label">Continent</InputLabel>
                  <Select
                    labelId="continent-label"
                    label="Continent"
                    name="continent"
                    value={values.continent}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    {Object.values(ContinentEnum).map((continent) => (
                      <MenuItem key={continent} value={continent}>
                        {continent}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.continent && errors.continent && (
                    <Typography variant="caption" color="error">
                      {errors.continent}
                    </Typography>
                  )}
                </FormControl>
              </Grid2>

              {/* Image Upload */}
              <Grid2 size={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Trip Photos
                </Typography>
                <ImageUploadTrip onUploadSuccess={(photoUrl: string) => handleAddImageUrl(photoUrl)} index={9999} />

                <Box sx={{ mt: 1 }}>
                  <Divider variant="middle" component="div" />
                  <Grid2 container spacing={2}>
                    {imageUrls &&
                      imageUrls.map((url, urlIndex) => (
                        <Box key={urlIndex}>
                          <Box component="img" src={url} alt="Preview" sx={{ height: 100, mt: 1 }} />
                          <IconButton aria-label="delete" onClick={() => handleDeleteImage(url)}>
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      ))}
                  </Grid2>
                </Box>
              </Grid2>

              {/* Itinerary */}
              <Grid2 size={12}>
                <Itinerary onChange={handleItinerary} itineraryItems={values.itinerary as DayItinerary[]} />
              </Grid2>

              {/* Included Services */}
              <Grid2 size={12}>
                <EditableAccordion
                  label="What's Included?"
                  items={values.includedServices}
                  onItemsChange={(items: any) => setFieldValue('includedServices', items)}
                />
                {touched.includedServices && errors.includedServices && (
                  <Typography variant="caption" color="error">
                    {errors.includedServices}
                  </Typography>
                )}
              </Grid2>

              {/* Excluded Services */}
              <Grid2 size={12}>
                <EditableAccordion
                  label="What is NOT Included?"
                  items={values.excludedServices}
                  onItemsChange={(items: any) => setFieldValue('excludedServices', items)}
                />
                {touched.excludedServices && errors.excludedServices && (
                  <Typography variant="caption" color="error">
                    {errors.excludedServices}
                  </Typography>
                )}
              </Grid2>
            </Grid2>

            {/* Status Messages */}
            {status && status.success && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle1" color="success.main">
                  Trip updated successfully!
                </Typography>
              </Box>
            )}
            {status && status.success === false && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle1" color="error">
                  {status.message || 'An error occurred while updating the trip.'}
                </Typography>
              </Box>
            )}

            {/* Submit Button */}
            <Box sx={{ mt: 3, textAlign: 'center' }}>
              <Button variant="contained" color="primary" type="submit" size="large" disabled={isSubmitting || loading}>
                {isSubmitting || loading ? pendingString : buttonString}
              </Button>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditTripForm;
